
.tv-show-container{
    margin-left: 20%;
    padding: 10px;
    display: flex;
}
.first-yotube-text{
    padding-left: 20px;
    margin-top: 50px;
    font-size: 20px;
    width: 500px;
}
.tv-show-container1 {
    margin-left: 20%;
    padding: 10px;
    display: flex;
}
.tv-show-container2{
    margin-left: 20%;
    padding: 10px;
    display: flex;
}
.tv-show-container3{
    margin-left: 20%;
    padding: 10px;
    display: flex;
}
.tv-show-container4{
    margin-left: 20%;
    padding: 10px;
    display: flex;
}
.radioachieve-head{
    background-color: black;
    color: white;
    margin-top: 5px;
    text-align: center;
    padding: 5px;
    font-size: 20px;
}
.culture-head{
    background-color: rgba(10, 10, 10, 0.753);
    color: white;
    margin-top: 5px;
    text-align: center;
    padding: 5px;
    font-size: 20px;
}
.content-img{
    width: 1200px;
    height: 600px;
    margin-left: 15%;
}
.contentimg-div{
    position: relative;
    margin-top: 1px;
}
.content-cover{
    position: absolute;
    margin-left: 15%;
    margin-top: 10%;
    background-color: rgba(0, 0, 0, 0.705);
    color: white;
    padding: 20px;
    width: 1159px;
    text-align: center;
    
}
.creativeare{
font-size: 30px;
font-weight: bold;
}
.contentsub{
background-color: rgba(255, 255, 255, 0.623);
font-size: 35px;
width: 350px;
padding: 10px;
margin-left: 35%;
color:blue;
border-radius: 20px;
}
.contentform-container{
    margin-left: 35%;
    background-color: antiquewhite;
    padding: 20px;
    width: 500px;
    box-shadow: 0px 2px 5px 2px;
}
.contentname{
    width: 500px;
    height: 40px;
    border-radius: 5px;
}
.contentselect{
    width: 500px;
    height: 40px;
    border-radius: 5px;
}
.contentdescribe{
   width: 500px;
   height: 100px; 
   border-radius: 5px;
}
.contentup{
    width: 100px;
    height: 40px;
}
.content-btn{
    width:120px ;
    height: 40px;
    border-radius: 20px;
    border: none;
    font-weight: bold;
}
.content-btn:hover{
    border: solid;
}
.movie1{
    display: flex;
    margin-left: 20%;
    padding: 10px;
    width: 900px;
    text-decoration: none;
    color: black;
    
}
.movie-img{
    width: 300px;
    height: 150px;

}
.movie-text{
    margin-left: 20px;
    font-size: 20px;
}
.movie-head{
    background-color: rgba(10, 10, 10, 0.753);
    color: white;
    margin-top: 5px;
    text-align: center;
    padding: 5px;
    font-size: 20px;
}
.movie1:hover{
    text-decoration: underline;
}

@media(max-width:670px){
    .contentimg-div{
        margin-left: -64px;
        
    }
    .content-img{
        width: 370px;
        height: 400px;
    }
    .content-cover{
        width: 330px;
        height: 190px;
    }
    .contentsub{
        margin-left: -20px;
    }
    .contentform-container{
        margin-left: 10px;
        width: 310px;
        
    }
    .contentname{
        width: 300px;
    }
    .contentselect{
        width: 300px;
    }
    .contentdescribe{
        width: 300px;
    }
    .content-btn{
        margin-left: 30%;
        background-color: brown;
        color: white;
        
    }
    .tv-show-container{
        margin-left: 0;
        display: grid;
        width: 300px;
        
    }
    .first-yotube-text{
        margin-left: 0px;
        text-align: center;
        width: 350px;
        margin-top: 20px;
    }
    .movie-container{
        margin-left: -25%;
    }
    .movie-img{
        width: 80px;
        height: 50px;
    }
    .movie-text{
        font-size: 12px;
        width: 250px;
    }
    .movie-head{
        text-align: center;
        padding-left: 20%;
    }
    
}
