
.lineimage{
    height: 30px;
    width: 47.2%;
    margin-left: 15%;
}
.newsimage-container{
    width: 315px;
    text-decoration: none;
    color: black;
}
.news-header{
    margin-left: 15%;
}
.new-container,.second-news{
    display: inline-flex;
    margin-left: 10%;
    gap: 8%;
    position: relative;
}
.news-p{
    font-weight: bolder;
}
.newsimage-container :hover{
    color: darkorange;
    cursor: default;
}
.newsimage{
    width: 315px;
    height: 250px;
}
.socialmedia-container{
    background-color: whitesmoke;
    border:whitesmoke;
    box-shadow:-1px 1px 5px 1px;
    position: absolute;
    z-index: 1;
    left:79% ;
    display: grid;
    padding: 10px;
    gap: 10px;
    width: 180px;
}


.facebook-icon{
    background-color: rgb(1, 93, 231);
    padding: 10px;
    text-align: center;
    width: 150px;
    border-radius: 20px;
    color: white;
    
}
.instagram-icon{
    background-color: brown;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
    color: white;
}
.twitter-icon{
    background-color:rgb(3, 161, 252) ;
    text-align: center;
    padding: 10px;
    border-radius: 20px; 
    color: white;

}
.tiktok-icon{
    background-color: black;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
    color: white;
}
.youtube-icon{
    background-color: red;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
    color: white;
}
.social-head{
    text-align: center;
    font-weight: bold;
    font-size: larger;
    

}
.facebook-icon :hover{
  transform: scale( 1.1, 1);
  transition: 0.5s;
}
.instagram-icon:hover{
  transform: scale( 1.1, 1);
  transition: 0.5s; 
}
.twitter-icon:hover{
    transform: scale( 1.1, 1);
    transition: 0.5s;  
}
.tiktok-icon:hover{
    transform: scale( 1.1, 1);
    transition: 0.5s;
}
.youtube-icon:hover{
    transform: scale( 1.1, 1);
    transition: 0.5s; 
}
.entertainment-header{
    margin-left: 15%;
} 
.entertainment-div{
    width: 290px;
    
    gap: 10px;
}
.entertainment-div:hover{
color: darkorange;
}

.entertainment-image{
    width: 230px;
    height: 180px;
    
}
.entertainment-container{
    display: flex;
    margin-left: 10%;
    width: 1100px;
    gap: 60px;
    cursor: default;
    position: relative;
}

.second-ent-line{
    display: flex;
    width: 1100px;
    gap: 60px;
    margin-left: 10%;
    cursor: default;
    position: relative;
    
    
}
.miss-container{
    position: absolute;
    margin-left: 69%;

    width: 200px;
    padding: 10px;
    
}
.display-missed{
    display: grid;
    gap: 5px;
    cursor: pointer;
    left: 55%;
    position: absolute;
    
}

/* laptop responsive */

/* mobile responsive */


.missed1{
    padding-bottom: 5px;
    border-radius: 20px;
    border: white solid;
    box-shadow: 2px 4px 2px;
    position: relative;
    margin-top: 10px;
    width: 90%;

}
.missedimage1{
    width: 90px;
    position: absolute;
    margin-top: 5px;
    padding-left: 5px;
    border-radius: 10px;

}
.missed-header{
    margin-right: 50px;
    text-align: center;

}
.fronad-container{
    left: 40%;
    width: 340px;
}
.miss-container{
    margin-left: 75%;
}


.missed-subhead,
.missed-time{
    margin-left: 100px;
}
.missed1:hover{
 background-color: darkorange;
 
}
.podcast-container{
    display: flex;
    gap: 60px;
    margin-left: 15%;
    height: 500px;
    width: 900px;
}
.podcast-div{
width:230px ;
cursor: default;

}
.podcast-div:hover{
color: darkorange;
}
.podcast-image{
    width: 230px;
    height: 180px;
}
.podcast-header{
    margin-left: 15%;
}
.pod-p,.ent-p{
    font-weight: bolder;
}
.missed-btn{
    width: 315px;
    padding: 15px;
   border-radius: 20px;
    /* background-color: aliceblue; */
    
}
.search-icon{
    margin-left: -50px;
    font-size: 25px;
    padding: 9px;
    position: absolute;
   
    margin-top: -2px;
    
    
}
.international-news{
    margin-left: 15%;
    height: 520px;
    width: 900px;
}
.entertain-div{
    margin-left: 15%; 
    height: 500px;
    width: 900px;
}
.missed-body{
    width: 350px;
}
.this-site{
    text-align: center;
}
.noNewsText{
    justify-content: center;
    text-align: center;
    color: red;
    margin-top: 10%;
    font-size: large;
}


@media(max-width:1376px){
    .missed-btn{
            max-width: 310px;
        }
     .display-missed{
        width: 100px;
    
     }
     .missed1{
        width: 250px;
     }
    .missed-header{
       text-align: center;
    }
    .socialmedia-container{
        margin-top: 30px;
    }
    .lineimage{
        width: 65%;
    }
     
    }
/* ipad responsive */
@media(max-width:992px){
.newsdiv-container{
    display: grid;
    grid-template-columns: auto auto !important;
}
.side-news-div{
    width: 250px;
    gap: 0;
    margin-left: -10%;
}
.newsimage{
    width: 200px;
}
.news-p{
    width: 200px;
}
.overflowdiv{
    overflow-x: hidden;
}

}
/* mobile responsive */
@media(max-width:670px){
    .socialmedia-container{
        display: none;
    }
    .display-missed{
        display: grid;
        gap: 5px;
        cursor: pointer;
        left: -10%;
        position: absolute;
        margin-top: 200px
         
    }

    .miss-container{
       position: absolute;
       top:2240px ;
       margin-left: 50px;
       
    }
    .international-news{
        margin-top: 650px;
        margin-left: 20px;
        width: 335px;
    }
    .lineimage{
        margin-left: 20px;
        width: 335px;
    }
    .podcast-container{

        margin-left: 20px;
        width: 335px;
        height:450px ;
    }
    .entertain-div{
        margin-left: 20px;
        width: 335px;
        
    }
    

    
}