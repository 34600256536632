


@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
.listenlive-container{
padding: 50px;
background-color: rgba(243, 134, 0, 0.308);
position: relative;
background-image: url(./../images/blue7.jpg);
height: 309px;
max-width: 100%;
display: none; 


}
.playing-image{
    width: 300px;
    border-radius: 10px;
    
    
}
.playing-imagediv{
    margin-left: 50%;
    
    position: absolute;
    margin-top: 88px;


}
.caller-div{
    background-color:black;
    text-align: center;
    font-weight: bolder;
    font-size: larger;
    color: white;
    padding: 15px;
    margin-top: 5px;
    
    
}
.toplive{
    padding: 9px;
    margin-left: 50%;
    width: 110px;
    border: solid;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    color: white;
    margin-top: -30px;
}
.onairnow-div{
    position: relative;
    padding-left: 30%;
    color: white;
    padding-top: 8%;
}
.playlive-icon{
    position: absolute;
    margin-left: -8%;
    margin-top: 15px;
    font-size: 100px;
    color: white;


}
.livett{
    margin-right: 3%;
    background-color: darkorange;
    padding: 7px;
    border-radius: 10px;
    
}
.ebe-header{
    color: darkorange;
}
.ne-container{
     display: block; 
    flex-wrap: wrap;
    align-items: center;
     gap: 5%; 
    font-size: larger;
    padding: 40px;
    
    width: 80%;
    
    
}
.img-new{
     height: 500px; 
     /* position: relative;  */
    float: left;
   width: 500px; 
   padding: 20px;
   display: block !important;

    
}
.news-head{
   margin-left: 2%; 
  /* background-color: aquamarine; */
   width: 65.5%;
   text-align: center;

    
}
.music-name{
    color: black;
    position: absolute;
    z-index: 2;
    top: 190px;
    font-weight: bold;
    padding: 5px;
}
.now-playing{
    color: rgb(255, 140, 0);
    font-size: 23px;
    margin-left: 35%;
    background-color: aliceblue;
    width: 70px;
    color: black;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 5px 2px 5px 2px;
    border: solid;
    
}
.contribute{
    color: darkorange;
    padding-left: 10px;
    font-weight: lighter;
}
.pre-image{
    width: 230px;
    height: 200px;
}
.pre-container{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 0px;
    margin-left: 10%;
}
.pre-text{
    margin-left: 15%;
}
.art-name{
    font-weight: bold;
}
.host-container{
    display: flex;
    gap: 170px;
    margin-top: 70px;
    position: relative;
    
} 
.host{
    width: 550px;
    height: 600px;
    position: relative;
    

}
.host-div{
    margin-left: 18%;
    
}
.prog-name{
    position: absolute;
    z-index: 2;
    padding: 10px;
    background-color: white;
    font-weight: bold;
    border-bottom-right-radius: 20px;
    font-size: 25px;
}
.prog-host{
    position: absolute; 
    z-index: 2 !important;
    top: 100%;
    background-color: black;
    color: white;
    width: 550px;
    padding: 15px;
    text-align: center;
    font-size: large;
    font-weight: bold;

}
.aboutshow-container{
    margin-left: 18%;
     margin-top: 20px; 

}
.reply-container{
    padding: 20px;
}
.contactform-div{
    
    background-color: alihite;
    padding: 5px;
    text-align: center;
    box-shadow: 1px 1px 5px;
    width: 800px;
    height: 450px;
    margin-left: 25%;
    
}
.container-header{
    
}
.contact-text{
    height: 250px;
    width: 600px;
}
.contact-email,
.contact-name{
    height: 50px;
    width: 250px;
    padding: 5px;
    margin-bottom: 10px;
    margin-left: 50px;
    
    
}
.com-head{
    padding-top: 20px;
    color: #060683;
}
.contact-name{
    margin-right: 50px;
    border-radius: 10px;

}
.contact-btn{
    width: 150px;
    height: 40px;
    margin-top: 10px;
    border-radius:5px ;
    background-color: brown;
    color: white;

}
.contact-head{
    
    color: white;
    background-color: darkorange;
    width: 200px;
    padding: 10px;
    margin-left: 37%;

}
.topnews-time{
    margin-left: 2.2%;
}
.newsdis-div{

   width: 1000px;
    
}
.newsdiv-container{
   
   
    display: grid;
    grid-template-columns: auto auto auto;
    
   
    
   
}
.newspage-pic1{
    width: 1000px;
    height: 600px;
    padding: 10px;
}
.sidenews-pic{
    width: 100px;
    background-color: aqua !important;
    
}
.side-news-div{
/* padding: 20px;
margin-left: 40px; */
grid-template-columns: auto auto auto;
box-shadow: 0px 1px 0px 0px;
border: solid white;
width: 400px;

/* margin-top: px; */
}
.newspage-head{
    padding-left: 20px;
}
.newspage-time{
    padding-left: 20px;
}
.newspage-text{
    font-size: larger;
    padding-left: 10px;
    
}
.sidenews-head{
    background-color: black;
    padding: 10px;
    width: 81%;
    color: white;
    margin-left: 10%;
    
}
.scheduleday-container{
padding: 10px;
margin-left: 5%;



}
.schedule-day{
    height: 50px;
    width: 8%;
    margin-left: 15px;
    font-weight: bolder; 
     border-radius: 10px; 
     z-index: 2 !important;
     position: relative;
    
}
.schedule-day:hover{
    background-color: black;
    color: white;
}
.schedule-head{
    text-align: center;
    text-decoration: solid;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.schedule-date{
    margin-left: 25px;
    font-size: 30px;
    font-weight: bold;
}

/* check */
.audio-player{
    bottom: 0;
    background-color: bisque;
    width: 400px;
    padding: 5px;
    margin-top: 20px;
    border: solid;
    box-shadow: 1px 4px 5px  ;
    border-color: white;
    position: absolute;
    z-index: 2;
    right: 20%;


}
.pop-pics{
    width: 300px;
    height: 400px;
    margin-left: 45px;
    border-radius: 20px;
    
    
}
.main-play{
    margin-left: 45px;
}
.player-head{
    text-align: center;
}
.play-date{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.remove-play{
    margin-left: 90%;
    font-size: 30px;
    color: red;
    cursor: pointer;
}
.player-name{
    position: absolute;
    top: 83.8%;
    margin-left:45px ;
    font-size: 20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-bottom-left-radius: 20px;
    
}
.moday1-image{
    width: 300px;
    height: 180px;
}
.schedule-social{
    margin-top: 10px;
    display: flex;
    gap: 10px;
    text-align: center;
    /* justify-content: center; */
    margin-left: 3%;
}
.facebook-ico{
    background-color: rgb(1, 93, 231);
    text-align: center;
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 50px;
    color: white;
}
.instagram-icon{
    text-align: center;
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 50px; 
}
.twitter-icon{
    text-align: center;
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 50px;  
}
.tiktok-icon{
    text-align: center;
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 50px;    
}
.youtube-icon{
    text-align: center;
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 50px;   
}
.facebook-ico:hover{
    transform: scale( 1.1, 1);
    transition: 0.5s; 
}
.getjob-container{
    top:0;
    justify-content:center;
    
   
}
.jobsearch-input{
  width: 68%;
  margin-left: 15%;
  height: 50px;
 border-radius: 10px;
  margin-top: 10px;
  z-index: 10 !important;
 position: relative;
 
}
.jobsearch-btn{
width: 6.7%;
height: 51px;
border-left: none;
background-color: darkorange;
color: white;

}
.jobsearch-image{
    width: 68%;
    height: 650px;
    margin-left: 15%;
    position: relative;

}
.jobsearch-text{


z-index: 1;



text-align: center;


}
.search-h1{
    font-size: 60px;
    color:black;
}
.search-p{
    font-size: 30px;
    
}
.joblisting-container{
    background-color: w;
    padding: 5px;
    box-shadow: 1px 0px 3px 0px;
    border: white solid;
    border-radius: 20px;
    
   
}

.jobnamemain{
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    color: #060683;
    font-weight: bold;
}

.joblisting-text{
    padding: 10px;
    justify-content: center;
    
}
.joblisting-all{
 
 
  margin-left: 15%;
   margin-top: 20px;
   width: 84%;

}
.jobcompany-name{
    font-size: 18px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}
.comadresss{
background-color: whitesmoke;
padding: 5px;
border-radius: 3px;

}
.apptype{
    background-color: whitesmoke;
padding: 5px;
border-radius: 3px;

}
.jobdetailsside{
    
     width: 60%; 
    /* margin-left:20% ; */
    padding: 10px;
}
.minedu{
    background-color: whitesmoke;
    padding: 5px;
    border-radius: 3px;
    width: 300px;
    
}
.minedus{
    background-color: rgb(71, 126, 71);
    padding: 5px;
    border-radius: 3px;
    color: white;
}
.jobdetails-side{
    display: flex;
    /* gap: 5%; */
}

.view-btn{
    text-decoration: none;  
    
}
.viemorejobs{
  font-style: italic;
  font-size: 17px;
   
   }


.view-btn:hover{
    transform: scale(1.1);
}
.job-category{
    margin-left: 15%;
    font-family: 'Lobster', cursive;
}
.showdetail-container{
    margin-top: 5%;
     margin-left: 5%; 
    width: 50%; 
    
    padding: 20px;
    border: white 1px solid ;
    
    box-shadow: 0px 0px 1px 0px;
    
}
.sidejod-advert{
    margin-top: 10%;
    width: 380px;
}
.com-name{
    font-weight: bolder;
}
.bag-icon{
    padding-left: 10px;
}
.apply-here{
    background-color: antiquewhite;
    padding: 15px;
    width: 90px;
    font-weight: bold;
}

.firstname-put{
width: 44.2%;
padding: 10px;
}
.lastname-put{
    width: 46.7%;
    margin-left: 50px;
    padding: 10px;
      
}
.email-put, .phone-put, .position-put{
    width: 97%;
    padding: 10px;
}
.additional-put{
    width: 97%;
    height: 200px;
    padding: 10px;
}
.resume-div{
   display: flex;
   gap: 28%; 
   margin-top: 9px;
}
.upload-btn{
    padding: 5px;
}
.submitform-btn{
    padding: 12px;
    margin-left: 29%;
    background-color:rgb(206, 14, 14);
    color: white;
    border-radius: 10px;
}
.applyform-container{
    /* background-color: ; */
    padding: 20px;
    width: 900px;
}
.jobformdata-container{
    margin-left: 5%;
}
.contactshow-container{
    margin-right: 10%;
    background-color:white;
    padding: 20px;
    width: 300px;
    height: 250px;
    border-radius: 10px;
    position: absolute;
    z-index: 3;
    right: 50px;

}
.jobdetails-container{
    display: flex;
    gap: 5%;
    margin-left: 10%;
}
.advertweb-view{
    position: absolute !important; 
    margin-top: 2%;
    width: 80%  !important;
     margin-left: 56% !important;
     text-align: center; 
    
    

}
.contactshow-head{
    color: brown;
    
}
.contactshow-call, .contactshow-email, .contactshow-whatssap{
    color: black;
}
.tv-container{
    margin-top: 4.5%;
    margin-left: 10%;
    width: 85%;
    
}
.watchtv-container{
    text-align: center;
}
.watch-tv{
    font-size: 50px;
    background-color: bisque;
    padding: 10px;
}
.gets-you{
    font-size: 30px;
}
.about-image{
margin-left: 35%;  
}
.transmit-head{
    text-align: center;
    font-size: 30px;
}
.about-text   {
    text-align: start;
    font-size: 20px;
    width: 1000px;
    margin-left: 20%;
}
.about-fre{
    color: red;
}
.newsletter-text{
    text-align: center;
    font-size: 20px;
}
.newsletter{
    
    margin-left: 23%;
    margin-top: 20px;
    border-radius: 10px;
    
}
.newsletter-text1{
    height: 50px;
    width: 800px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; 
    border-right: none;
    
}
.advertsideview{
    /* position: absolute; */
    /* margin-left: 20%; */
    margin-top: 2%;
    color: darkorange;
    text-align: center;
}
.newsletter-btn{
    padding: 18.5px;
    width: 100px;
    background-color: aliceblue;
    color:white;
    border-left: 30px;
    border-start-end-radius: 10px;
    border-end-end-radius: 10px;
    color: black;
    position: absolute;
    margin-top: 0.7px;
    
    
}
.showjob2{
    display: grid;
   gap: 20px;
   width: 80% !important;
   
}
/* laptop responsive */

.games-div{
    text-align: center;
    font-size: 40px;
}
.number-table{
    border: solid;
}
.theads{
    border: solid;
    padding: 30px;
    color: red;
    font-size: xx-large;
}
.numtable-container{
     margin-left: 12.5%; 
    padding: 20px;
     width: 60%;
    margin-top: 10px;
    justify-content: center;
    
}
.tbodys{
    font-size: xx-large;
}
.tb{
    padding: 15px;
}
.type1{
    
}
.ada-pic{
   
   height: 450px; 
   width: 400px;
   padding: 5px;
}
.ada-img-container{
    margin-left: 25%;
    display: flex;
}
.adahead-text{
text-align: center;
}
.ada-text{
margin-left: 18%;
font-size: 20px;
width: 1100px;

}
.reining-head{
    font-size: 25px;
    background-color: black;
    color: white;
    padding: 5px;
    width: 350px;
    padding-left: 30px;
    border-radius: 3px;
    margin-left: 20%;
}
.adawinner-container{
display: flex;
margin-left: 10%;
}
.winner-img{
    width: 300px;
    height: 400px;
    margin-left: 10%;

}
.lady-text{
    width: 500px;
    font-size: 20px;
    margin-left: 9%;
}
.winner-name{
    color: brown;
    font-weight: bold;
}
.previous-ada-body{
    font-size: 25px;
    
}
.previousada{
    padding-left: 150px;
}
.adatable-container{
    margin-left: 40%;
}
.previousqueen-head{
    margin-left: 20%;
    font-size: 25px;
    background-color: black;
    color: white;
    padding: 5px;
    width: 350px;
    padding-left: 30px;
    border-radius: 3px;
}
.note{
    text-align: center;
    font-size: 20px;
    color: white;
    background-color: black;
    padding: 10px;
}
.testside{
display: flex;
}
.advertspace-container{
/* background-color: antiquewhite; */
display: grid;
 margin-left:-480px ; 
width: 40%;



}
.first-side-advertcontainer{

   margin-top: 80%;   
  width: 378px !important;
margin-left: 0%;



}
.cardside-one{
    width:80% !important ;
    
}
.schedule-container{
    margin-top: 10px;
  
}
.schedules-sidem{
    display: flex;

}
.proDetails{
    
    width: 700px;
    text-align: start;
    background-color: rgba(0, 0, 0, 0.87);
    color: white;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   
}
.searchjob-btn{
    height: 50px !important;
    margin-top: -5px;
}
.formsubmit-container{
    margin-left: 4% !important;
    margin-top: 3%;
    display: block !important;
}
.adwebview{
    width: 70% !important;
    margin-left: 15%;
}
.schedule-bodyoverflow{
    overflow-x: hidden;
    display: flex;
}
.abouttext-div{
     /* background-color: aquamarine;  */
    width: 100%;
    text-align: center;

}

/* new loader style */
.newsletter-about{
    margin-left: 0;
}
.second-side-advertcontainer{
    width: 378px !important;
    margin-left: 0%;  
}
.detailsNews{
    /* text-align: justify; */
    padding: 20px;
    cursor: pointer;
}
.displayblocks{
   
   width: 30% !important;
   width: 30% !important;
   
    margin-left: 10%;
    background-color: #F7F7F7;
    margin-top: 6%;
   padding: 10px;
   border-radius: 10px;

}
.topnewsbody{
    display: block;
}
.displayreply-head{
    text-align: center;
    padding: 10px;
    font-weight: bolder;
    color: darkorange;
}
.reply-form-boot{
    padding: 10px !important;
}
.btn-center{
    text-align: center !important;
    width: 90%;
    margin-left: 5%;
}
.allcomments-container{
    margin-top: 5%;
    margin-left: 5%;
}
.imgreply-box{
    display: flex;
    margin-top: 2%;
}
.imgreply{
    width: 30px;
    height: 30px;
    border-radius: 50px;
}
.replymainname{
    padding: 10px;
    font-weight: bold;
}
.replymaintext{
   
    width: 130% !important;
    padding: 10px;
    display: block;
   

 }
 .comment-textarea{
    width:100% ;
    height: 100% !important;
    border-radius: 10px;
 }

 /* loaderrr circle  */

 .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    align-items: center;
    text-align: center;
    color: #060683;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid black;
    color: darkorange;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  .comment-line{
    width: 60%;
    color: darkorange;
  }
  .readmoretry-style{
    color: blue !important;
    cursor: pointer;
  }
  .jobdetails-entry{
    text-align: start;
   

  }
  .second-addiv{
    width: 378px !important;
    height: 300px;
  
    
}
.newsall-container{
    display: flex;
    gap: 5%;
}
.newsadvert-container{
    width: 430px;
    margin-top: 4%;
    
}
.commentdiv-head{
    font-weight: bold;
    font-style: italic;
    padding: 10px;
}
.advertimage-img{
    width: 300px !important;
    height: 350px;
    padding: 10px;
    
}
.loaderless{
    text-align: center;
    color: darkorange;
}
.applybtn-job{
    text-align: center;
    margin-left: 40%;
    background-color: #060683 !important;
    color: white !important;
}
.applybtn-job:hover{
 background-color: rgb(17, 16, 16) !important;
}







.container-alt {
    
   
    
    justify-content: center;
   
    margin-block: 3rem;
    gap: 2rem;
    width: 85% !important;
    justify-content: center !important;
    margin-left: 8% !important;
  }
  

  
  .card-alt {
  
     width: 350px !important; 
   
    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    background: #ECE9E6;
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
  
  }
  
  
  
  .card__body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  
  
  .tag {
    align-self: flex-start;
    padding: .25em .75em;
    border-radius: 1em;
    
  }
 
  .newstext-alt{
    width: 300px;
    text-align: center;
    color: black;
  }
.newstext-alt:hover{
    color: darkorange;
}

  .tag-blue {
    background: #56CCF2;
  background: linear-gradient(to bottom, #060683, #56CCF2);
    color: #fafafa;
  }
  
  .tag-brown {
    background: #D1913C;
  background: linear-gradient(to bottom, #FFD194, #D1913C);
    color: #fafafa;
  }
  
 
  
  .card__body h4 {
    font-size: 1.5rem;
    text-transform: capitalize;
  }
  
  .card__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
  }
  
  .user {
    display: flex;
    gap: .5rem;
    color: #060683;
    font-size: 13px;
  }
  
  .card__image {
    width: 300px;
    height: 200px;
    text-align: center;
    margin-left: 4%;
    padding: 3px;


  }

  .jobtime{
    margin-left: 5%;
    width: 200px;
  }
  .newsnav-general{
    text-decoration: none !important;
    
  }

  .blueload{
    color: #060683;
    background-color: #060683;
  }
  .darkorangeload{
    color: darkorange;
    background-color: darkorange;
  }
  .jobflexw{
    
    
  }

 .searchicon-jobs{
    position: absolute;
    margin-left: -4%;
    margin-top: 1.2%;
    z-index: 15;
 } 




/* new alert style */

  .alerts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .myAlertModal {
    max-width: 300px;  
    /* margin: 4.75rem auto; */
    position: absolute;
    bottom: 0px;
    right: 105px;
  }
  
/* end alert */

.presenter-divs-ske{
    display: flex;
    gap: 5%;
    margin-left: 20% !important;
}




/* job button style */
.button-57 {
  position: relative;
  overflow: hidden;
  border: 1px solid #18181a;
  color: #18181a;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  padding: 18px 18px 17px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-57 span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button-57 span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.button-57:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #060683;
 color: white;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  /* transform: skewY(9.3deg) scaleY(0); */
  z-index: 50;
}

.button-57:hover:after {
  transform-origin: bottom center;
   /* transform: skewY(9.3deg) scaleY(2);  */
}

.button-57:hover span:last-child {
  /* transform: translateX(-50%) translateY(-100%); */
  opacity: 1;
   transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1); 
}
.postjobdisplay{
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  display: flex;
  gap: 0%; 
  margin-left: 10%  !important; 
}
.jobspost-container{
    background-color: rgb(12, 12, 34);
    justify-content: center;
   
}
.jobsposting-image{
    width: 600px;
    height: 500px;

}
.jobpostingform{
    background-color: white !important;
    padding: 20px !important;
    width: 500px !important;
    
    margin-left: 10%  !important;
}
.reacchout-job{
    font-size: 25px;
}
.jobposting-header{
    text-align: center;
    color: white;
    padding: 10px;
    font-size: 50px;
  font-family:fantasy ;
}
.jobtitled{
    height: 70px !important;
}
.postjobform-head{
    color: #060683;
}

.presenter-div22{
    background-color: #e5e5e5;
    border-radius: 15px;
    box-shadow: 2px 2px 8px 0px ;
     width: 60%; 
    margin-left:0 !important;
  }



.container-jobtext {
   
    position: relative;
     width: 50%; 
     background-color: #56cbf22c; 
     color: white;
    justify-content: center; 
    /* align-items: center; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   /* position: relative; */
  }
  
  .jobtext-h1 {
     padding: 20px;
    font-size: 50px;
    color: var(--primary-color);
    transform: translateY(-600px);
    animation: 1.2s slideIn ease-in-out forwards 1s;
    z-index: 10;
    opacity: 0;
    /* position: relative; */
    text-align: start;
    margin-left: 7%;
    
  }
  
  .jobtext-h1::before {
      content: '';
      width: 0%;
     
      background-color: var(--secondary-color);
      position: absolute;
    
      animation: 1s underline ease-in-out forwards 2s;
      mix-blend-mode: screen;
  }
  
  .overlay {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      opacity: 0;
      left: 0;
      right: 0;
      background-color: var(--secondary-color);
      transform: scale(.5);
      animation: .5s slideIn ease-in-out forwards, 1s skewBg ease-in-out;
  }
  
  @keyframes skewBg {
    0% {
      transform: scale(.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes underline {
    100% {
      width: 100%;
    }
  }
  
  @keyframes slideIn {
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .findtext-job{
    font-size: 25px;
    margin-top: 10%;
    color: darkorange;
    text-align: center;
  }
  .arrow-downjob{
    text-align: center ;
    margin-left: 22%;
  }
  .ebejob-name{
      color: darkorange;
  }
  .jobpost-secondsction{
   display: flex;
   gap: 2%;
   margin-top: 3%;
   justify-content: center;
  }
  .how-does{
    text-align: center;
    font-size: 50px;
    font-weight: bold;
  }
  .downjob-container{
   
    text-align: center;
    padding: 5px;
    width: 25%;
    box-sizing: border-box 5px;
    box-shadow: 0px 0px 2px 0px;
    border-radius: 5px;
    border-color: #fafafa;
  }
  
  .downjob-icon{
    color: #060683;
  }
  .downtext-p{
    font-size: 20px;
  }
 
  .emailsjob{
    font-weight: bold;
  }
  .ebeemailjob{
    color: #060683;
    text-decoration: underline;
  }
  
.news-comment-underline{
    height: 5px !important;
    background-color: #09223b;
    width: 65%;
}

.allProgram22{
  background: #FFF;
  border: 1px solid #CDD3E2;
  box-shadow: 0px 0px 1px #CCC;
  padding: 40px 40px 60px;
  margin-top: 40px;
  border-radius: 4px; 
  margin-left: 10% !important;
  margin-right: 10% !important;
}


 
  /* CSS */
  .button-18 {
    align-items: center;
    background-color: #0A66C2;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    margin-left: 60% !important;
  }
  
  .button-18:hover,
  .button-18:focus { 
    background-color: #16437E;
    color: #ffffff;
  }
  
  .button-18:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
  }
  
  .button-18:disabled { 
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
  }
 
.sendemailspan{
    color: #060683 ;
    text-decoration: underline;
}

.no-jobs{
    text-align: center !important;
    color: red;
    justify-content: center;
}

.alert {
    min-height: 55px;
    width: 560px;
    max-width: 90%;
    border-radius: 12px;
    padding: 16px 22px 17px 20px;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 15;
    margin-left: 5% !important;
    
  }
  .alert-warning {
    background: var(--warning);
  }
  .alert-success {
    background-color: rgb(8, 128, 8) !important;
  }
  .alert-primary {
    background: var(--primary);
  }
  .alert-dark {
    background: var(--dark);
  }
  .alert-error {
    background-color: rgb(189, 9, 9) !important;
    color: white !important;
  }
  .alert .icon__wrapper {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.253);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alert .icon__wrapper span {
    font-size: 21px;
    color: #fff;
  }
  .alert p {
    color: #fff;
    font-family: Verdana;
    margin-left: 10px;
  }
  .alert p a,
  .alert p a:visited,
  .alert p a:active {
    color: #fff;
  }
  .alert .open {
    margin-left: auto;
    margin-right: 5px;
  }
  .alert .close, .alert .open {
    color: #fff;
    transition: transform 0.5s;
    font-size: 18px;
    cursor: pointer;
  }
  .alert .close:hover, .alert .open:hover {
    transform: scale(1.3);
  }


.secheader-job{
    font-style: italic !important;
    font-size: 15px !important;
    
}

.pagination-job{
    text-align: center;
    margin-top: 1%;
}
.pagination-job-button{
    width: 40px;
     
    
    gap: 5px;
}

.toast-success-btn{
  
   z-index: 20 !important;
   position: fixed;
   margin-left: 45% !important;
   margin-top: 5% !important;
}

.toast-success-btnapply{
  
    z-index: 20 !important;
    position: fixed;
    margin-left: 0 !important;
    margin-top: 5% !important;
 }

.toast-success-apply{
  
    z-index: 20 !important;
    position: fixed;
    margin-left: 0 !important;
    margin-top: 5% !important;
 }
 


.toast-alert-div-d{
    position: fixed;
    margin-top: 10% ;
}
.toaterter-run{
    margin-top: 30% !important;
    position: absolute;
}
#tout-success{
    background-color: rgb(11, 117, 11) !important;
    color: white !important;
    
}
.star-required{
    color: red;
}
.remove-erroricon{
    padding-left: 20px;
}
.down-createjob-arrow{
    margin-top: 15%;
}

.central-nesletter{
    justify-content: center;
	align-items: center;
}

.subscribe {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: white;
	width: 945px;
	height: 474px;
	border-radius: 10px;
	box-shadow: 0 4px 20px rgba(61, 159, 255, 0.2);
    text-align: center;
    margin-left: 20%;
   
}


.subscribe__title {
	font-weight: bold;
	font-size: 26px;
	margin-bottom: 18px;
}

.subscribe__copy {
	max-width: 450px;
	text-align: center;
	margin-bottom: 53px;
	line-height: 1.5;
}

.form {
	margin-bottom: 25px;
}

.form__email {
	padding: 20px 25px;
	border-radius: 5px;
 	border: 1px solid #CAD3DB;
	width: 431px;
	font-size: 18px;
	color: #0F121F;
}

.form__email:focus {
	outline: 1px solid #3D9FFF;
}

.form__button {
	background: #3D9FFF;
	padding: 10px;
	border: none;
	width: 244px;
	height: 65px;
	border-radius: 5px;
	font-size: 18px;
	color: white;
	box-shadow: 0 4px 20px rgba(61, 159, 255, 0.7);
}

.form__button:hover {
	box-shadow: 0 10px 20px rgba(61, 159, 255, 0.7);
}

.notice {
	width: 345px;
}

.presenter-div-mobile{
    display: none;
}
.scheduleday-BTn{
  width: 100% ;
  margin-left: 5%;
}



@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800');
.error-container {
  text-align: center;
  font-size: 180px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 20px 15px;
}
.error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #FFB485;
}
.error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.error-container > span:nth-of-type(1) {
  color: #D1F2A5;
  animation: colordancing 4s infinite;
}
.error-container > span:nth-of-type(3) {
  color: #F56991;
  animation: colordancing2 4s infinite;
}
.error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}
.error-container > span:nth-of-type(2):before,
.error-container > span:nth-of-type(2):after {
  border-radius: 0%;
  content:"";
  position: absolute;
  top: 0; left: 0;
  width: inherit; height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 30px 0 rgba(239, 250, 180, 0.4),
        inset -30px 0 0 rgba(255, 196, 140, 0.4), 
        inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  animation: shadowsdancing 4s infinite;
}
.error-container > span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
      transform: rotate(45deg);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 30px 0 rgba(239, 250, 180, 0.4),
        inset -30px 0 0 rgba(255, 196, 140, 0.4), 
        inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
        inset 0 30px 0 rgba(209, 242, 165, 0.4),
        inset -30px 0 0 rgba(239, 250, 180, 0.4), 
        inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
     box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 30px 0 rgba(245, 105, 145, 0.4),
        inset -30px 0 0 rgba(209, 242, 165, 0.4), 
        inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
   box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
        inset 0 30px 0 rgba(255, 196, 140, 0.4),
        inset -30px 0 0 rgba(245, 105, 145, 0.4), 
        inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 30px 0 rgba(239, 250, 180, 0.4),
        inset -30px 0 0 rgba(255, 196, 140, 0.4), 
        inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@keyframes colordancing {
  0% {
    color: #D1F2A5;
  }
  25% {
    color: #F56991;
  }
  50% {
    color: #FFC48C;
  }
  75% {
    color: #EFFAB4;
  }
  100% {
    color: #D1F2A5;
  }
}
@keyframes colordancing2 {
  0% {
    color: #FFC48C;
  }
  25% {
    color: #EFFAB4;
  }
  50% {
    color: #D1F2A5;
  }
  75% {
    color: #F56991;
  }
  100% {
    color: #FFC48C;
  }
}

/* demo stuff */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/* body {
  background-color: #416475;
  margin-bottom: 50px;
} */
/* html, button, input, select, textarea {
    font-family: 'Montserrat', Helvetica, sans-serif;
    color: #92a4ad;
} */
.h1404-head {
  text-align: center;
  margin: 30px 15px;
}
.zoom-area { 
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.link-container {
  text-align: center;
}
a.more-link {
  text-transform: uppercase;
  font-size: 13px;
    background-color: #92a4ad;
    padding: 10px 15px;
    border-radius: 0;
    color: #416475;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}
.navhome-{
    text-decoration: none;
    cursor: pointer;
}
.ebeona-logo-promo{
    width: 100px;
    height: 100px;
}


.nofoundpage-container{
  background: darkgray;
  height: 100vh;
}


.category-card {
    border-radius: 16px;
    box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
    width: 100%;
    max-width: 70%;
    height: 100%;
    background-color: #EEEEEE;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    
   
    margin-left: 15%;
    margin-top: 1%;
  }
  .category-card .tag {
    display: inline-block;
    background-color:#050563;
    color: #EEEEEE;
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0.5em 0.75em;
    line-height: 1;
    border-radius: 6px;
  }
  .tag + * {
    margin-top: 1rem;
  }
  .category-card h2 {
    font-size: 1.5rem;
    color: #202020;
    line-height: 1.25;
  }
  .category-card h2 + * {
    margin-top: 1rem;
  }
  .category-card p {
    color: #202020;
  }
  .category-card p + * {
    margin-top: 1.25rem;
  }
  .category-card button {
    font: inherit;
    line-height: 1;
    background-color: #EEEEEE;
    border: 2px solid #050563;
    color: #050563;
    padding: 0.5em 1em;
    border-radius: 6px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    cursor: pointer;
  }
  .category-card button:hover,.category-card button:focus {
    background-color: #050563;
    color: #EEEEEE;
  }
  .secondname-com{
    color: #888 !important;
  }
  .timecompany-logo{
    margin-top: -6px;
  }
   .body2conshow{
     background-color:#F1F1F2; 
  } 



@media(max-width:1400px){
    .joblisting-container{
        margin-left: -45px;
    }
    .jobsearch-btn{
        padding-top: 16px;
    }
    .newsdiv-container{
        gap: 0px;
         
        margin-left: 0%;
       padding: 20px;  
       display: grid;
       grid-template-columns: auto auto auto;
       
       gap: 0 !important;
       
      } 
      .showjob2{
        margin-left: 4% !important;
        width: 77% !important;
      } 
      .first-side-advertcontainer{
       
         /* margin-top: 200%;  */
         width: 330px !important;
            margin-left:45% !important; 
        } 
        .second-side-advertcontainer{
            width: 330px !important;
            margin-left: 45% !important; 
           
        }
        .second-addiv{
            width: 200% !important;
            display: none;
        }
        .cardside-one{
            width:91.3% !important ;
            
        }
        .newsadvert-container{
            width: 388px;
            margin-top: 4%;
        }
        .sidejod-advert{
            margin-top: 10%;
            width: 332px;
        }

        .searchicon-jobs{
            margin-top: 1.7%;
        }
        .news-comment-underline{
            height: 5px !important;
            background-color: #09223b;
            width: 60%;
        }
    
}

/* ipad responsive */
@media(max-width:993px){
   .cardside-one{
    display: none !important;
   }
   .aboutshow-container{
    margin-left: 40px;
   }
   .hostdetailsHead{
    text-align:center ;
    padding: 10px;
   }

   .proDetails{
    width:90% ;
    margin-left: 5%;
    font-size: 15px;
    /* text-align: center; */
    background-color: rgba(0, 0, 0, 0.87);
    color: white;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   
    
}


   .host-container{
    overflow-x: hidden;
   }
   .body2conshow{
    overflow-x: hidden;
   }
   .host-container{
     margin-left: -40px; 
   }
   .scheduleday-container{
    display: block;
    margin-left: 0%;
   
   }
   .schedule-day{
   
    grid-template-columns: auto auto auto auto ;
    width: 120px;
    
   }
   .ne-container{
    display: grid ;
   }
   .news-head{ 
    width: 700px;
   }
   .contactform-div{
    display: none;
    margin-left: 0;
    width: 500px;
   }
   .detailsNews{
    width: 700px;
   }
   .img-new{
    width: 700px;
   }
   .numtable-container{
    margin-left: 10%;
    width: 80%;
   }
   .adaogidi-container{
    margin-left: -33%;
   }
   .ada-text{
    width: 65%;
    margin-left: 30%;
    
    
   }
   .reining-head{
    margin-left: 45%;
   }
   .lady-text{
    margin-left: 30%;
   }
   .previousqueen-head{
    margin-left: 45%;
   }
   .adahead-text{
    text-align: center;
    margin-left: 20%;
   }
   .about-image{
    margin-left: 20%;
   }
   .about-text{
    margin-left: 10%;
    width: 80%;
   }
   .newsletter-about{
    display: none;
    margin-left: -30%;
    width: 90%;
   }
   .showjob2{
    display: grid;
    grid-template-columns: auto;
    margin-left: -8% !important;
    
   }
   .scheduleday-container{
    text-align: center;
    justify-content: center;
    display: block;
    
    
    
 }
 .joblisting-container{
    margin-left: 12% !important;
 }
  .joblisting-image{
    width: 250px !important;
 }
 .jobflexw{
    width: 200px;
    padding: 10px;
 }
 .minedu{
    width:180px ;
 }
 .applybtn-job{
    
    margin-left: 30%;
   
}
.postjobdisplay{
   
    font-weight: bold;
    text-align: center;
    display: grid;
    gap: 0%; 
    margin-left: 5%  !important; 
  }
  .jobtext-h1 {
    padding: 20px;
   font-size: 30px;
   color: var(--primary-color);
   transform: translateY(-600px);
   animation: 1.2s slideIn ease-in-out forwards 1s;
   z-index: 10;
   opacity: 0;
   /* position: relative; */
   text-align: start;
   
 }
 .container-jobtext {
   
    position: relative;
     width: 94%; 
     background-color: #56cbf22c; 
     color: white;
    justify-content: center; 
    /* align-items: center; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   /* position: relative; */
  }

.jobpost-secondsction{
    display: grid !important;
    gap: 2%;
    margin-left: 10%;
    margin-top: 3%;
   }
   .downjob-container{
   
    text-align: center;
    padding: 5px;
    width: 90%;
  }
  .jobdetailsside{
    
    width: 80%; 
   /* margin-left:20% ; */
   padding: 10px;
  
}
.showdetail-container{
    margin-top: 5%;
     margin-left: 5%; 
    width: 1500px; 
    
    padding: 20px;
    border: white 1px solid ;
    
    box-shadow: 0px 0px 1px 0px;
    
}
.jobdetails-side{
    display: grid;
}
.news-comment-underline{
    height: 5px !important;
    background-color: #09223b;
    width: 93%;
}

.subscribe {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: white;
	width: 700px;
	height: 474px;
	border-radius: 10px;
	box-shadow: 0 4px 20px rgba(61, 159, 255, 0.2);
    text-align: center;
    margin-left: 5%;
   
}


 
}


/* mobile responsive */
@media(max-width:680px){
    .main-play{
        display: block;
        width: 250px;
        background: none;
        margin-top: -95px;
        height: 35px;
        margin-left: 40px;
    }
    
    .remove-dis{
 display: none;
 }
 .audio-player{
    left: -50px;
    position: absolute;
    width: 0px;
    height: 0;

    
 }
 .schedule-head{
   text-align: center;
   left: 0%;
   margin-left: -2%;
 }
 .schedule-day{
    margin: 5px;
    border-radius: 20px;
    border: none;
    width: 200px;
   
 }
 .scheduleday-container{
  text-align: center;
  background-color: #09223b;
  /* margin-left: -5%; */
  justify-content: center;
   
    
}
 
 .ada-img-container{
    display: grid;
    margin-left: 8px;
    
 }
 .ada-pic{
    width: 350px;
 }
 .reining-head{
    margin-left: -60px;
    width: 325px;
 }
 #hideday-display{
    /* display: none; */
   margin-top: 5px !important;
 }
 .adahead-text{
    margin-left: -60px; 
 }
 .ada-text{
    margin-left:10px ;
    width: 350px;
    text-align: center;
 }
 .reining-head{
    margin-left:10px ;
 }
 .adahead-text{
    margin-left: -8px;
 }
 .adawinner-container{
    display: grid;
    margin-left: -30px;
 }
 .winner-img{
    margin-left: 70px;
 }
 .lady-text{
    
    width: 360px;
    text-align: center;
    padding: 5px;
 }
 .previousqueen-head{
    margin-left: 10px;
    width: 320px;
 }
 .adatable-container{
    margin-left: 20px;
 }
 .previousada{
    
 }
 .about-image{
    margin-left: 30px;
    width: 300px;
    margin-left: 5%;
 }
 .about-text{
    
    margin-left: 10%;
    width: 80%;
 }
 .newsletter-text1{
    width: 250px;
    margin-left: -80px;
    height: 40px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
 }
 .newsletter-btn{
    height: 45px;
    padding: 4px;
    padding-top: -10px;
 }
 .showjob2{
    display: grid;
    grid-template-columns: auto;
    margin-left: -25% !important;
    width: 370px !important;
    
 }
.host-container{
 margin-left: -30%;
 margin-top: 0;
 
}
.display-missed{
    display: none;
}
.host{
    width: 363px;
    height: 400px;
}
.prog-host{
    width: 100%;
    margin-top: -57px;
    z-index: 4;
    margin-left: 0%;
}
.aboutshow-container{
    margin-left: -5px;
    text-align: center;
   
}
.side-news-div{
     margin-left: -10%; 
     width: 160px;
     overflow: hidden; 
     /* padding: 30px; */
     box-shadow: 0px 1px 0px 0px;
     display: flex;
     padding: 10px;
     
}

.sidenews-head{
    width: 100%;
    /* margin-top: -30px; */
    text-align: center;
    
     margin-left: 0px; 
}

.contactform-div{
    margin-top: 100px;
    margin-left: 10%;
    width: 300px;
    height: 400px;
    display: block;
}
.contact-text{
    width: 280px;
    height:150px ;
}
.contact-head{
    text-align: center;
    margin-left: 15%;
}
.contact-name{
    margin-left: 5px;
    width: 280px;
    height: 50px;
    border-radius: 10px;
}
.first-side-advertcontainer{
    display: none;
}
.second-side-advertcontainer{
    display:none ;
}
.cardside-one{
    display: none !important;
}
.ne-container{
    display: grid;
    /* margin-left: -2%; */
    font-size: 15px;
    padding: 0;
    gap: 0;
}
.img-new{
    width: 375px;
    height: 300px;
    margin-left: -2%;
    float: none;
    
}
.numtable-container{
    
     width: 100% !important; 
    /* overflow-x: hidden; */
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
}


.adwebview{
    /* width: 200px;
    background-color: bisque; */
}
.proDetails{
    width:90% ;
    margin-left: 5%;
    font-size: 15px;
    /* text-align: center; */
    background-color: rgba(0, 0, 0, 0.87);
    color: white;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   
    
}
.newsimage{
    width: 100px !important;
    height: 100px;
    margin-left: 5% !important;

}
.newsimage-container{
    display: flex;
    padding: 5px;
    gap: 5px;
    margin-left: 20%;
    width: 100% !important;
}
.news-p{
    font-size: 12px;
    width: 100%;
    padding: 20px;
}
.detailsNews{
    width: 360px !important;
    
    font-size: 18px;
    margin-left: 2%;
    padding-left: 20px !important;
}

.showdetail-container{
    
   
    text-align: center;
    width:500rem !important;
    margin-left: -5%;
   
}
.news-head{
    width: 350px;
    text-align: center;

}
.getjob-container{
   
    
}
.jobsearch-image{
    width: 300px;
    height: 300px;
}
.search-h1{
    font-size: 25px;
}
.search-p{
    font-size: 18px;
}
.jobsearch-text{
    
    /* width: 305px; */
}
.jobsearch-input{
    margin-left: 35px;
    width: 82% !important;
}
.schedule-social{
    margin-top: 10px;
    display: flex;
    gap: 10px;
    text-align: center;
    justify-content: center;
}
.displaymainnews-container{
    overflow-x: hidden;
     margin-left: 0%; 
    
}
.topnews-time{
    margin-left: 7%;
}
.newsdiv-container{
    display: grid;
    
    margin-left: 10px !important;
    grid-template-columns: auto auto !important;
    
    width: 100%;
    
} 
.aboutus-container{
    margin-left: 0;
}
.abouttext-div{
    margin-left: 0;
    text-align: center;
   
    
}
.new-container{

     margin-left: -30px; 
    display: grid !important;
     grid-template-columns: auto !important;


}
.newsimage-container{
    display: grid;
}
.monday1-image{
    width: 200px;
    height: 200px;
    margin-left: 40% !important;
    top: -20px;
    display: none;
    
    
}
.adwebview{
    width: 100% !important;
    overflow-x: scroll; 
    margin-left: 0%;
}

#advertweb-view{
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
    }
    .joblisting-container{
      
    }
    .schedule-bodyoverflow{
        overflow-x: hidden;
    }
    .newsmobile-div-container{
        display: grid !important;
    }
   .allcomments-container{
    margin-top: 30%;
    margin-left: 5%;
}
.displayblocks{
   
    width: 80% !important;
    width: 80% !important;
    
     margin-left: 10%;
     background-color: whitesmoke;
     margin-top: 6%;
    padding: 10px;
 
 }
 .replymaintext{
  
    width: 90% !important;
    padding: 10px;
 }
 .loaderless{
    text-align: center;
    align-items: center;
    margin: 0%;
 }
 .view-btn{
    margin-left: 14%;
 }
 .body2conshow{
    background-image: url("../images/equipment-empty-musical-recording-room.jpg");
    /* background-size: cover; */
    /* background-color: rgba(250, 235, 215, 0.048); */
    
} 
.hostdetailsHead{
    text-align:center ;
    padding: 10px;
    background-color:white;
    color: rgb(8, 8, 155);
    padding: 10px;
    /* margin-top: 10%; */
   }
   #mobilenews-display{
    display: flex !important;
   }
   #imagenewsid{
    width: 80px;
    height: 80px;
    margin-top: 30px;
   }
   .newstext-alt{
      font-size: 15px !important;
      width: 200px !important;
      padding: 10px;
   }
   .tag-blue{
    display: none;
   }

.container-alt {
    
   
    
    justify-content: center;
   
    margin-block: 3rem;
    gap: 0rem;
  }
  .jobdetails-side{
    display: grid;
    /* gap: 5%; */
}
.jobdetailsside{
    
    width: 100%; 
   /* margin-left:20% ; */
   padding: 10px;
}
.searchicon-jobs{
    position: absolute;
    margin-left: -10%;
    margin-top: 5%;
 } 
 .showdetail-div{
    text-align: start;
 }
 .applybtn-job{
    
    margin-left: 0%;
   
}
.postjobdisplay{
   
    font-weight: bold;
    text-align: center;
    display: grid;
    gap: 0%; 
    margin-left: 5%  !important; 
  }
.jobtext-h1 {
    padding: 20px;
   font-size: 30px;
   color: var(--primary-color);
   transform: translateY(-600px);
   animation: 1.2s slideIn ease-in-out forwards 1s;
   z-index: 10;
   opacity: 0;
   /* position: relative; */
   text-align: start;
   
 }
 .jobsposting-image{
    width: 250px;
    height: 250px;

}
.container-jobtext {
   
    position: relative;
     width: 94%; 
     background-color: #56cbf22c; 
     color: white;
    justify-content: center; 
    /* align-items: center; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   /* position: relative; */
  }
  .jobpost-secondsction{
    display: grid;
    gap: 2%;
    margin-left: 5%;
    margin-top: 3%;
   }
   .downjob-container{
   
    text-align: center;
    padding: 5px;
    width: 95%;
  }
.presenter-divs-ske{
    display: none;
    gap: 10% !important;
    margin-left: 12% !important;

}
.job-category{
    margin-left: 5%;
}
.news-comment-underline{
    height: 5px !important;
    background-color: #09223b;
    width: 94%;
}
.advertspace-container{
    /* background-color: antiquewhite; */
   display: none;
    
    }

.subscribe {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;
        width: 350px;
        
        border-radius: 10px;
        box-shadow: 0 4px 20px rgba(61, 159, 255, 0.2);
        text-align: center;
        margin-left: 3%;
       }

       .form__email {
        padding: 20px 25px;
        border-radius: 5px;
         border: 1px solid #CAD3DB;
        width: 300px;
        font-size: 18px;
        color: #0F121F;
    }
    .form__button {
        background: #3D9FFF;
        padding: 10px;
        border: none;
        width: 200px;
        height: 50px;
        border-radius: 5px;
        font-size: 18px;
        color: white;
        box-shadow: 0 4px 20px rgba(61, 159, 255, 0.7);
        margin-top: 2px;
    }
    .category-card {
    
      width: 100%;
      max-width: 90%;
      margin-left: 5% !important;
     
  }
  .tag {
    align-self: flex-start;
    padding: .25em .75em;
    border-radius: 1em;
    margin-left: 30%;
    
  }
  .scheduleday-BTn{
    
    margin-left: 1%;
  }
  .allProgram22{
   
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .host-div{
    margin-left: 18%;
    
}
  
  
    

}




 