


@import "rsuite/dist/rsuite.css";
.adminside{
    background-color: rgb(44, 40, 40);
    color: white;
    /* position: absolute; */
    width: 240px;
    height: 100%;
    /* display: grid; */
    text-decoration: double;
    margin-top: 25% !important;
    list-style-type: none;
    padding-top: 90px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    scroll-behavior: auto;
    overflow-y: auto;
    max-height: calc(150vh - 2rem);
    transition-delay: 1ms;
    
}

.nowsidecontainer{
    
    background-color:black !important;
    position: fixed !important;
     overflow-y: auto; 
    
}

.adminside-nav::-webkit-scrollbar-thumb{
    background-color: white;
  outline: 1px red;
  
}

.action-bar{
    background-color: rgb(220, 220, 220);
    display: flex;
    margin-top: 80px;
    padding: 13px;
    padding-left: 16%;
      position: fixed;  
    list-style-type: none;
    font-size: 18px;
   
    width: 100%;
    z-index: 10;
    
}

.action-barr{
    background-color: rgb(220, 220, 220);
    display: flex;
    
    padding: 13px;
    padding-left: 19%;
     position: absolute; 
    list-style-type: none;
    font-size: 18px;
   
    width: 100%;
    z-index: 10;
}

.under{
    
    /* position: absolute; */
    margin-top: 10px;
    width: 208px;
    height: 0px;
    margin-top:20px;
    color: darkorange;
    margin-left: 0px;
    opacity: 0.5;

}
.prog-table{
       
       
        background-color: white;
    
        padding: 18px;
        border: solid;
        border-collapse: collapse;
        width: 30%;
        height: 15%;
        border-radius: 10px;
}
.prog-table2{
   
   
    background-color: white;

    padding: 18px;
    border: solid;
    border-collapse: collapse;
    width: 50%;
   
    border-radius: 10px;

}
.allprograms-card{
    
   
    background-color: white;
    justify-content: center;
    margin-left: 36%;
    border-radius: 15px;
    padding: 20px;
    width: 540px;
    text-align: center;
    border:whitesmoke solid  ;
    box-shadow:  1px 2px 7px;
    margin-top: 2%;
    
}

.allprograms-card-skeleton{
    
   
    background-color: white;
    justify-content: center;
    margin-left: 36%;
    border-radius: 15px;
    padding: 20px;
    width: 540px;
    height: 600px !important;
    text-align: center;
    border:whitesmoke solid  ;
    box-shadow:  1px 2px 7px;
    margin-top: 2%;
    
}


.tdd{
    border: 0.1px ;
    height: 20px;
    padding: 10px;
    
}
.current-head{
    position: absolute;
margin-top: 37%;
margin-left: 25%;
background-color: rgb(2, 2, 133);
font-size: 30px;
padding: 10px;
font-weight: bold;
color: white;
width: 38.9%;
text-align: center;

}
.weekly-table{
    margin-top: 15%;
    position: absolute;
    margin-left: 25%;
    background-color: white;
    
    padding: 20px;
    border: solid;
    border-collapse: collapse;
    width: 40%;
}
.program-namm{
    color:rgb(2, 2, 133) ;
}
.weeklys-table{
    margin-top: 23%;
    position: absolute;
    margin-left: 25%;
    background-color: white;
    
    padding: 20px;
    border: solid;
    border-collapse: collapse;
    width: 40%;
}
.admindash-nav{
    color: white;
}
.cardstyle{
    
   
   
    width: 21%;
    
    border-radius: 15px;
    padding: 20px;
    /* width: 220px; */
    cursor: pointer;

    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 40px 25px 20px;
   
    
}
.flush{
    text-align: center;
}
.card-head{
    text-align: center;
   margin-top: 2%;
    font-size: 25px;
    font-weight: bold;
    color: rgb(8, 8, 172);
    border-radius: 20px; 
}
.cardstyle1{
   
    
   
   
    
    border-radius: 15px;
    
    
    text-align: center;
    width: 21%;
  
    cursor: pointer;

    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 40px 25px 20px;
   

}
.cardstyle2{
   
  
   
   
    
    border-radius: 15px;
    cursor: pointer; 
    width: 21%;
    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 40px 25px 20px;
}

.cardstyle3{
    
    text-align: center;
    cursor: pointer;
     width: 21%;
    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 40px 25px 20px;
    
}
.card-head2{
    text-align: center;
   
    font-size: 25px;
    font-weight: bold;
    color: rgb(8, 8, 172); 
    border-radius: 20px;
   
}
.card-number{
    font-size: 30px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.programs-drop{
    /* display: none; */
    position: absolute;
}
.programs-menu:focus .programs-drop{
 display: block;
}
.createprogram-nav{

    color: white;

}
.program-table{
    margin-left: 20%;
    margin-top: 60%;
    position: absolute;
    width: 1300px;
    border-collapse: collapse ;
    border: solid 1px;
    
    
}
.programp-head{
    position: absolute;
    margin-top: 40%;
    background-color:rgb(2, 2, 133);
    color: white;
    width: 1300px;
    text-align: center;
    
}
.startp-btn {
height: 40px;
width: 100px;
margin-left: 5px;
border-radius: 5px;
background-color: rgba(52, 52, 245, 0.877) !important;
margin-top: 2px;
color: white !important;
text-align: center;


}
.endp-btn{
    height: 40px;
    width: 100px;
    border-radius: 15px; 
    margin-left: 5px;
   background-color: brown;

}
.removep-btn{
    height: 40px;
    width: 100px;
    margin-left: 5px; 
    border-radius: 15px;
    background-color: white;
}
.removep-btn:hover{
    background-color: rgb(153, 7, 7);
    color: white;
  }

.removenum{
    height: 40px;
    width: 100px;
    margin-left: 5px; 
    border-radius: 15px;
    background-color:white;
    border: none;
    margin-top: 4px;  
}
.removenum:hover{
    background-color: rgb(161, 6, 6);
}

.select-program{
    margin-left: 2px;
    height: 44px;
}
.startl{
    margin-left: 3px;
}
.program-drops{
    margin-left: 30px;
    font-weight: lighter;
}
.drops{
    font-size: 25px;
    margin-top: -1px;
    position: sticky;
}
.createmployee-container{
    margin-top: 10%;
    margin-left: 35%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
     box-shadow: 0px 0px 3px 0px; 
    border:white solid  ;
   width: 530px;
   position: absolute;
}
.updateemployee-form{
    margin-top: -90%;
    left: -500%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
     box-shadow: 0px 0px 3px 0px; 
    border:white solid  ;
    position: absolute;  
}
.viewemployee-table{
    margin-left: 20%;
    margin-top: 12%;
    position: absolute;
    width: 1300px;
    border-collapse: collapse ;
    border: white 1px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px;
    padding-bottom: 10px; 

}
.createmployee-head{
    text-align: center;
    color: darkorange;
}
.createnews-form{
    position: absolute;
    margin-top: 10%;
    margin-left: 35%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
     box-shadow: 0px 0px 3px 0px; 
    border:white solid  ;
}
.newstopic-input{
    width: 600px;
    height: 60px;
    border-radius: 10px;
}
.newstopic-inputs{
    width: 450px;
    height: 60px;
    border-radius: 10px;
}


.select-news{
    width: 400px;
    height:60px ;
    border-radius: 10px;
    margin-left: 35px;
    position: relative;
}
.news-details{
    width: 600px;
    height: 200px;
    border-radius: 10px;
}
.news-detailsed{
    width: 470px;
    height: 200px;
    border-radius: 10px;
}
.createnews-head{
    text-align: center;
    color: darkorange;
}
.createnews-btn{
    margin-left: 38%;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: rgb(51, 51, 197);
    color: white;
}
.createnews-btn:hover{
    border: none;
}
.createprogramform{
    margin-top: 10%;
    margin-left: 35%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
     box-shadow: 0px 0px 3px 0px; 
    border:white solid  ;
    position: absolute; 
    width: 600px;
}
.createprogram-head{
    text-align: center;
    color: darkorange;
}
.createp-input{
    width: 570px;
    height: 50px;
    border-radius: 10px;
}
.createp-inputs{
    width: 470px;
    height: 50px;
    border-radius: 10px;
}
.createp-content{
    width: 570px;
    height: 10px;
    border-radius: 5px;
}
.createp-contents{
    width: 470px;
    height: 100px;
    border-radius: 5px;
}
.createprogram-select{
    width: 570px;
    height:60px ;
    border-radius: 5px;
    
}
.createprogram-weekday{
    width: 450px;
    height:40px ;
    border-radius: 5px;
    margin-top: 5px;
    
}

.createprogram-sele{
    width: 470px;
    height:60px ;
    border-radius: 5px;   
}

.createp-content{
    width: 570px;
    height:140px ;
    border-radius: 5px;
    
}
.createprogram-select1{
    width: 570px;
    height:60px ;
    border-radius: 5px;
}
.program-tables1{
    margin-left: 20%;
    position: absolute;
    width: 1300px;
    border-collapse: collapse ;
    border: solid 1px;
    box-shadow: 0px 0px 5px 0px;
    margin-top: 12%;
    
    
    border: white 1px;
    background-color: white;
   
    padding-bottom: 10px; 

}
.advertrateview-table{
    margin-left: 17%;
    margin-top: 17%;
    position: absolute;
    width: 1000px;
    border-collapse: collapse ;
    border: solid 1px;
    box-shadow: 0px 0px 5px 0px;
    border: white 1px;
    background-color: white;
}
.viewemployee-head{
    position: absolute;
    margin-left: 0%;
    margin-top: 7%;
    color: darkorange;
    background-color: #030334;
     width: 100%; 
    text-align: center;
    padding: 20px;
   
}
.createmployee-btn{
    height: 40px;
    width: 170px;
    border-radius: 5px; 
    margin-left: 35%;
   background-color: rgb(51, 51, 197);
   color: white;
}
.updatemployee-btn{
    height: 40px;
    width: 170px;
    border-radius: 5px; 
    margin-left: 1%;
   background-color: rgb(51, 51, 197);
   color: white;
   border: none;
   

}

.createmployee-btn:hover{
    border: none;
}
.creatmployee-radio{
    margin-left: 70%;
}
.viewemploye-head{
    position: absolute;
    margin-left: 50%;
    margin-top: 8%;
    color: darkorange;
}
.viewad-head{
    position: absolute;
    margin-left: 20%;
    margin-top: 9%;
    color: darkorange;
     
}
.tbview-head{
    background-color: rgb(5, 5, 129);
    color: white;
}
/* ///////////////////////////////////////////////////// */
.login-btn{
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: rgb(5, 5, 129);
    color: white;
    border: black solid 1px;
    margin-left: 80px;
    
    }
 .login-btn:hover{
    background-color: darkorange;
    border: none;
    
 }   
.signup-btn{
    margin-left: 80px;
    height:28px ;
    width: 80px;
    border-radius: 5px;
    border: none;

    

}
.login-form{
    position: absolute;
    left:35% ;
    background-color: #eeeeee;
    padding: 35px;
    border:solid #f1f1f1;
    margin-top: 200px;
    height: 350px;
    width: 530px;
    
  
}

.input-phone{
    width: 450px;
    height: 45px;
    border: none;
    margin-left: 0px;
    
}
.pass{
    width: 450px;
    height: 45px; 
    border: none;
    
}
.exit-btn{
    margin-left: 170px;
    color: rgb(187, 5, 5);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
    cursor: pointer;
    
}
.logd{
    text-align: center;
}
.addash{
    color: rgb(10, 10, 231);

}

.createem{
    color: rgb(10, 10, 231);  
}
.dashbbb{
    font-size: 18px;
    
}
.dashbbb:hover{
    color: darkorange;
}

.admin-footer{
    background-color: darkorange;
    /* position: absolute; */
    width: 100%;
    
    height: 80px;
    text-align: center;
    
}
.inicon{
  color: rgb(119, 119, 241);
  /* position: absolute; */
  margin-left: -23px;
  margin-top: 0px;  
}
.shift-nav{
    margin-top: 10px;
    color:darkorange;
    margin-left: 5%;
    text-align: center;
    border-radius: 3px;
}
.adminside-one:hover{
color: darkorange !important;


}
.adminside-one{
    margin-left: 20px;
    font-size: 14px;
}
.shift-nav:hover{
color: white;
background-color: #030334;

}
.cum-error{
    color: rgba(245, 7, 7, 0.589);
}
.btn-down{
    color: black;
    position: absolute;
    background-color:whitesmoke;
    padding: 20px;
    list-style-type: none;
    border-radius: 3px;
    z-index: 2;
    /* display: none; */
    margin-left: -5px;
    margin-top: 10px;
    width: 110px;

}
.changepassbtn{
    border: 0 !important;
    background-color: aliceblue !important;
    color: black !important;
    margin-left:-3px !important;
}


.down-drop2{
    margin-top: 10px;
    
}
.tableline{
    width: 1300px;
     position: absolute; 
    margin-top: -1px; 
    left: 0;
}
.table-heads{
    background-color: rgba(184, 194, 194, 0.288);
    padding: 10px;
    width: auto;
}
.action-icon-drop{
    position: absolute;
}
.password-access{
    width: 250px;
    height: 40px;
    border-radius: 5px;
}
.access-select{
    width: 260px;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px; 
}
.access-modal{

}
.cancel-access{
width: 125px;
height: 40px;
border-radius: 5px;
color:black;
background-color: whitesmoke;
border: none;
}
.grant-access{
margin-left: 10px;
width: 125px;
height: 40px;
border-radius: 5px;
background-color: rgb(71, 71, 245);
color: white;
border: none;

}
.content{
    
    border-radius: 0px;
    
}
.side-main-icon{
    margin-left: -25px;
    color: white;
    margin-top: -3px;
    
    font-size: 17px;
}
.display-update-side{
    position: absolute;
    background-color: aliceblue;
    color: black;
    z-index: 3;
    list-style-type: none;
    padding: 20px;
    font-weight: bold;
}
.popform-hide{
    margin-left: 0px;
    margin-left: 30%;
    position: absolute;
}
.adaogidi-table{
    position: absolute !important;
    margin-top: 15%;
    max-width: 70%  !important;
    margin-left: 14%;

}
.contentname{
   width: 460px; 
}
.inputchange-password{
    width: 700px;
    height: 45px;
    border-radius: 5px;
}
.submit-change-pass{
    height: 40px;
    width: 170px;
    border-radius: 5px; 
    margin-left: 5%;
   background-color: rgb(51, 51, 197);
   color: white;
}
.changepassbtn{
    
    margin-left: 20px;
    margin-top: 10px;
}

.btn-remove{
    color: black;
    text-align: center;
    background-color:whitesmoke;
    padding: 20px;
    list-style-type: none;
    border-radius: 3px;
    width: 200px;
    border-radius: 10px;
    margin-left: 30%;
    
}    
.adminside-nav{
    
     position:fixed ;  
    height: 100%; 
   margin-top: 0%; 
   z-index: 2;
   
}
.current-programhead{
    text-align: center;
}
.news-radio{
  margin-left: 30px;
  position: relative;
 
}
.radionews{
    margin-top: 7px;
    position: absolute;
    margin-left: 3px;
}
.news-radios{
color: green;
}
.markbody{
    width: 20px;
    height: 15px;
}
.paidtime-input{
    width: 210px;
    height: 50px;
    border-radius: 5px;
    margin-top: 3px;
}

.ccpay{
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    text-align: center;
    width: 100%;
}
.listinput{
    height: 40px;
    width: 115px;
    margin-left: 3px;
    border-radius: 5px;
    margin-top: 5px;
}
.cancelad{
    border: none;

}
.wide-advert{
    max-width: 60% !important;
    padding: 10px;
   
}
.add-advertbutton{
    margin-top: 20%;
}
.deleteadvertbtn{
    margin-left: 3px;
    border: none;
    color: red;
}
.ispending{
    margin-left: 10px;
}
.errorp{
    color: red;
}

.firstline-drop{
    display: inline-flex !important;
    

}
#dropdown-autoclose-outside{
    position: relative !important;
    z-index: 0 !important;
    
}
.sideside{
   
    width: 150px !important;
    margin-left: 80px;
}
.errlogin{
    color: red;
    text-align: center;
}
.failednotice{
    text-align: center; 
    color: white;
    background-color: rgba(0, 0, 0, 0.432);
}
.failed{
    color:red ;
}
.alert-display-positive{
    position: absolute !important;
    z-index: 5 !important;
    margin-top: 7%;
    text-align: center;
    width: 70%;
    margin-left: 15%;
}
.edit-image-cont{
    width: 100px;
    height: 100px;
    border: solid;
    border-radius: 10px;

}
.deleteshift{
    margin-left: 2%;
    color: red !important;
}
.login-cover-overflow{
    overflow-x: hidden;
}


/* second loader  */
.loaderss {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.rsuit-container{
    margin-top: 5%;
    scroll-behavior: auto !important;
    overflow-y: auto !important;
    position: relative;
   
    position: fixed;
}
.rsuit-container::-webkit-scrollbar-thumb{
    background-color: white;
  outline: 1px red;
  
}

.logoutdownicon{
    color: red !important;



}


.cross-checknews{
    background-color: #88888831;
    text-align: center;
    padding: 5px;
    color: darkorange;
}
.cross-checknew{
    color: darkblue;

}

.lds-ellipsis {
    display: inline-block;
    width: 200px;
    height: 50px;
    z-index: 20 !important;
    position: fixed;
    margin-left: 50% !important;
    margin-top: 6% !important;
    color: rgba(9, 9, 243, 0.89) !important;
    font-style: italic;
    font-size: large;
    
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    
    background: darkorange;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
   
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  





 /* Style the modal */
.modaled {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal Content */
  .modal-contents {
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
  }
  
  .modal-texted {
    margin-left: 14px;
    font-size: 24px;
  }
  
  /* Create the circle loader with pure CSS */
  .loadered {
    width: 50px;
    height: 50px;
    border: 8px solid #ddd;
    border-top: 8px solid #2196f3;
    border-bottom: 8px solid #2196f3;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  #adminpage-allcontainer{
    background-image: url("../images/360_F_357445774_J6HSwV46UGq80NiMfalLjcJVmfwHnyVw.jpg") !important;
    background-size: cover;
   
    bottom: 0;
  }
  
  /* .logindiv-container{
    background-image: url("../images/pngtree.jpg") !important;
    background-size: cover;
    z-index: 3;
  } */

.closemenu{
    margin-left: -10% !important;
    padding-right: 8px;
    position:absolute ;
}
.admin-cards-div{
    display: flex;
    justify-content: center;
    gap: 2%;
    padding: 10px;
    padding-top: 10%;
    padding-left:12% ;
    padding-right:12% ;
    
}
.admintable-div{
    display: flex;
    margin-left: 20%;
    gap: 10%;
    margin-top: 3%;
    padding:10px;
}
.showicon-admin{
    color: rgb(39, 39, 241);
}

.sidescroll-nav{
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    scroll-behavior: auto !important;
    overflow-y: auto !important;
    position: relative;
    display: block;

}
#shift-nav-applyjob{
margin-left: 5% !important;
}
.removeadd-day{
    margin-left: 2% !important;
    border-radius: 10px;
    color: white;
    height: 40px;
}
.nameadd-day{
    margin-top: 1%;
    width: 65%;
    margin-left: 5%;
}






.heading5{
   color: blue !important; 
   
}
.c-dashboardInfo {
    margin-bottom: 15px;
  }
  .c-dashboardInfo .wrap {
    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 40px 25px 20px;
    height: 100%;
  }
  .c-dashboardInfo__title,
  .c-dashboardInfo__subInfo {
    color: #6c6c6c;
    font-size: 1.18em;
  }
  .c-dashboardInfo span {
    display: block;
  }
  .c-dashboardInfo__count {
    font-weight: 600;
    font-size: 2.5em;
    line-height: 64px;
    color: #323c43;
  }
  .c-dashboardInfo .wrap:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    content: "";
  }
  
  .c-dash-cover:nth-child(1) .wrap:after {
    background: linear-gradient(82.59deg, darkorange 0%,  #0084f4 100%);
  }
  .c-dashboardInfo:nth-child(2) .wrap:after {
    background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
  }
  .c-dashboardInfo:nth-child(3) .wrap:after {
    background: linear-gradient(69.83deg, #0084f4 0%,black 100%);
  }
  .c-dashboardInfo:nth-child(4) .wrap:after {
    background: linear-gradient(81.67deg, #0084f4 0%, darkorange 100%);
  }
  .c-dashboardInfo__title svg {
    color: #d7d7d7;
    margin-left: 5px;
  }
  .MuiSvgIcon-root-19 {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
  }
  



  /* div.table-title {
    display: block;
   margin: auto;
   max-width: 600px;
   padding:5px;
   width: 100%;
 }
 
 .table-title h3 {
    color: #fafafa;
    font-size: 30px;
    font-weight: 400;
    font-style:normal;
    font-family: "Roboto", helvetica, arial, sans-serif;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    text-transform:uppercase;
 }
  */
 
 /*** Table Styles **/
 
 .table-fill {
   background: white;
   border-radius:3px;
   border-collapse: collapse;
   height: 320px;
   margin: auto;
   max-width: 50%;
   padding:5px;
   width: 100%;
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
   animation: float 5s infinite;
 }
  
 .th-table {
   color:#D5DDE5;;
   background:#1b1e24;
   border-bottom:4px solid #9ea7af;
   border-right: 1px solid #343a45;
   font-size:23px;
   font-weight: 100;
   padding:24px;
   text-align:left;
   text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
   vertical-align:middle;
 }
 
 .th-table:first-child {
   border-top-left-radius:3px;
 }
  
 .th-table:last-child {
   border-top-right-radius:3px;
   border-right:none;
 }
   
 .tr-table {
   border-top: 1px solid #C1C3D1;
   border-bottom-: 1px solid #C1C3D1;
   color:#666B85;
   font-size:16px;
   font-weight:normal;
   text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
 }
  
 .tr-table:hover td {
   background:#4E5066;
   color:#FFFFFF;
   border-top: 1px solid #22262e;
 }
  
 .tr-table:first-child {
   border-top:none;
 }
 
 .tr-table:last-child {
   border-bottom:none;
 }
  
 .tr-table:nth-child(odd) td {
   background:#EBEBEB;
 }
  
 .tr-table:nth-child(odd):hover td {
   background:#4E5066;
 }
 
 .tr-table:last-child td:first-child {
   border-bottom-left-radius:3px;
 }
  
 .tr-table:last-child td:last-child {
   border-bottom-right-radius:3px;
 }
  
 .td-table {
   background:#FFFFFF;
   padding:20px;
   text-align:left;
   vertical-align:middle;
   font-weight:300;
   font-size:18px;
   text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
   border-right: 1px solid #C1C3D1;
 }
 
 .td-table:last-child {
   border-right: 0px;
 }
 
 .td-table.text-left {
   text-align: left;
 }
 
 .td-table.text-center {
   text-align: center;
 }
 
 .td-table.text-right {
   text-align: right;
 }
 
 .td-table.text-left {
   text-align: left;
 }
 
 .td-table.text-center {
   text-align: center;
 }
 
 .td-table.text-right {
   text-align: right;
 }
 .dropdownshow-d{
    z-index: 50 !important;
    /* margin-left: -120% !important; */
    position: absolute !important;
    margin-left: 80%;
    
  
  }




@media(max-width:1702px){
   .adaogidi-table{
    margin-top: 16%;
   } 
}




 






 @media(max-width: 1400px){
    .admintable-div{
        display: flex;
        margin-left: 30%;
        gap: 10%;
        margin-top: 3%;
        padding:10px;
    }

    .admin-cards-div{
        display: flex;
        justify-content: center;
        gap: 2%;
        padding: 10px;
        padding-top: 15%;
        padding-left:12% ;
        padding-right:12% ;
        
    }
    .adminside{
        background-color: rgb(44, 40, 40);
        color: white;
        /* position: absolute; */
        width: 240px;
        height: 100%;
        /* display: grid; */
        text-decoration: double;
        margin-top: 25% !important;
        list-style-type: none;
        padding-top: 90px;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        scroll-behavior: auto;
        overflow-y: auto;
        max-height: calc(150vh - 3rem);
        
    
        
    }

    .viewemployee-head{
        position: absolute;
        margin-left: 0%;
        margin-top: 10%;
        color: darkorange;
        background-color: #030334;
        width: 100%; 
        text-align: center;
        padding: 20px;
       
    }
    .adaogidi-table{
        position: absolute !important;
        margin-top: 21%;
        max-width: 70%  !important;
        margin-left: 20%;
    
    }
    .action-bar{
      
        padding-left: 20%;
        
    }
}
/* tablet responsive style */
@media(max-width:993px){
    .admin-cards-div{
        
        /* padding-top: -30px;
        display: grid;
        grid-template-columns: auto auto;
        */
      
    }
    .allprograms-card{
    
   
        background-color: white;
        justify-content: center;
        margin-left: 15%;
        border-radius: 15px;
        padding: 20px;
        width: 540px;
        text-align: center;
        border:whitesmoke solid  ;
        box-shadow:  1px 2px 7px;
        margin-top: 20px;
        
    }
    .admintable-div{
        display: flex;
        margin-left: 10%;
        gap: 5%;
        margin-top: 3%;
        padding:10px;
    }
    .createmployee-container{
        margin-top: 20%;
        margin-left: 35%;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
         box-shadow: 0px 0px 3px 0px; 
        border:white solid  ;
       width: 450px;
      
    }
    .contentselect{
        width: 420px;
    }
    .adaogidi-table{
       
        margin-top: 25%;
        max-width: 70%  !important;
        margin-left: 35%;
    
    }
    .createnews-form{
       
        margin-top: 20%;
        margin-left: 35%;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
         box-shadow: 0px 0px 3px 0px; 
        border:white solid  ;
        width: 460px;
    }
    .newstopic-input{
        width: 430px;
        height: 60px;
        border-radius: 10px;
    }
    .newstopic-inputs{
        width: 450px;
        height: 60px;
        border-radius: 10px;
    }
    .news-details{
        width: 430px;
        height: 200px;
        border-radius: 10px;
    }
    .login-form{
        margin-left: -20%;
    }
    .createprogramform{
        margin-top: 20%;
        margin-left: 35%;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
         box-shadow: 0px 0px 3px 0px; 
        border:white solid  ;
        position: absolute; 
        width: 450px;
    }
    .createprogram-select{
        width: 420px;
    }
    .createprogram-select1{
        width: 420px;
    }
    .createp-content{
        width: 420px;
        height:140px ;
        border-radius: 5px;
        
    }
    .sideside{
        margin-left: 20% !important;
        width: 150px !important;
    }
    .add-advertbutton{
        margin-top: 40%;
        margin-left: 60%;
        width: 200px;
    }
    .contentname{
        width: 420px; 
     }
     .createmployee-btn{
        
     }
     .endlabeled{
        margin-left: 0px;
    }
    .createp-input{
        width: 420px;
        height: 50px;
        border-radius: 10px;
    }
    
    .createprogram-weekday{
        width: 300px;
        height:40px ;
        border-radius: 5px;
        margin-top: 5px;
        
    }
    .viewemployee-head{
        position: absolute;
        margin-left: 0%;
        margin-top: 18%;
        color: darkorange;
        background-color: #030334;
        width: 100%;
        text-align: center;
        padding: 20px;
       
    }
    .adaogidi-table{
        position: absolute !important;
        margin-top: 30%;
        max-width: 70%  !important;
        margin-left: 20%;
    
    }
    .action-bar{
      
        padding-left: 33%;
        
    }

}

         /* mobile responsive style */
    @media(max-width:680px){
        .admin-cards-div{
            display: grid;
            /* grid-template-columns: auto;
            justify-content: center;
            gap: 2%;
            padding: 10px;
            padding-top: 10%;
            margin-top: 30%;
            margin-left: 0%; */
            
        }
        .allprograms-card{
    
   
            background-color: white;
            justify-content: center;
            margin-left: 20%;
            border-radius: 15px;
            padding: 20px;
            width: 290px;
            text-align: center;
            border:whitesmoke solid  ;
            box-shadow:  1px 2px 7px;
            margin-top: 15%;
            
        }
        .action-bar{
            background-color: rgb(220, 220, 220);
            display: flex;
            margin-top: -10%;
            padding: 13px;
            padding-left: 19%;
            position: fixed;
            list-style-type: none;
            font-size: 18px;
           
            width: 100%;
            z-index: 10;
            
        }

        .cardstyle, 
        .cardstyle1,
        .cardstyle2,
        .cardstyle3{
       width: 100%;
    
        }


        .admintable-div{
            display: grid;
            margin-left: 4%;
            gap: 5%;
            margin-top: 3%;
            padding:10px;
        }
        .prog-table{
            margin-left: 13%;
        }
        .admin-footer{
            background-color: darkorange;
            /* position: absolute; */
            width: 100%;
            
            height: 80px;
            text-align: center;
            margin-top: 10%;
            
        }
        .logindiv-container{
           
        }
        .login-form{
           
            left:25% !important ;
            background-color: #eeeeee;
            padding: 35px;
            border:solid #f1f1f1;
            margin-top: 200px;
            height: 350px;
            width: 335px;
            
          
        }
        .input-phone{
            width: 300px;
            height: 45px;
            border: none;
            margin-left: -20px;
            
        }
        .pass{
            width: 300px;
            height: 45px;
            border: none;
            margin-left: -20px;
            
        }
        .login-btn {
          margin-left: -9%;
        }
        .adminside-nav{
    
            position:fixed ;  
          
          margin-top: -25%; 
          z-index: 2;
          width: 5%;
       }
       .adminside{
        font-size: 12px;
        margin-left: -40%;
        width: 110px;
        margin-top: 500% !important;
       
       }
       .adminside-one{
        font-size: 8px;
       }
       .program-drops{
        margin-left: -20%;
       }
       .createmployee-container{
        margin-top: 30%;
        margin-left: 20%;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
         box-shadow: 0px 0px 3px 0px; 
        border:white solid  ;
       width: 290px;
       
    }
    .contentselect{
        width: 265px;
    }
    .dashbbb{
        font-size: 12px;
        
    }
    .createmployee-btn{
        margin-left: 8%;
    }
    .adaogidi-table{
         position: relative;
        margin-top: 35%;
        max-width: 70%  !important;
        margin-left: 20%;
    
    }
    .createnews-form{
       
        margin-top: 10%;
        margin-left: 20%;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
         box-shadow: 0px 0px 3px 0px; 
        border:white solid  ;
        margin-top: 30%;
        width: 290px;
    }
    .newstopic-input{
        width: 260px;
        height: 40px;
        border-radius: 10px;
    }
    .newstopic-inputs{
        width: 200px;
        height: 60px;
        border-radius: 10px;
    }
    .news-details{
        width: 260px;
        height: 100px;
        border-radius: 10px;
    }
    .createnews-btn{
        margin-left: 15%;
    }
    .createprogramform{
        margin-top: 30%;
        margin-left: 20%;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
         box-shadow: 0px 0px 3px 0px; 
        border:white solid  ;
        
        width: 290px;
    }
    .createprogram-select{
        width: 260px;
        height:60px ;
        border-radius: 5px;
        
    }
    .createp-content{
        width: 260px;
        height: 100px;
        border-radius: 5px;
    }
    .createprogram-select1{
        width: 260px;
        height:60px ;
        border-radius: 5px;
    }
    .add-advertbutton{
        margin-top: 35%;
        margin-left: 30%;
        width: 150px;
    }
    .contentname{
        width: 260px; 
     }
     .dropdownshow-d{
        z-index: 50 !important;
        margin-left: 80% !important;
       
      
      
      }
      .sideside{
        margin-left: 0%;
        width: 150px !important;
    }
    .createp-input{
        width: 260px;
        height: 50px;
        border-radius: 10px;
    }
    .prog-table2{
   
   
        background-color: white;
    
        padding: 18px;
        border: solid;
        border-collapse: collapse;
        width: 50%;
       margin-left: 13%;
        border-radius: 10px;
    
    }
    .creatmployee-radio{
        margin-left: 0%;
    }
    .createprogram-weekday{
        width: 170px;
        height:40px ;
        border-radius: 5px;
        margin-top: 5px;
        
    }
    .viewemployee-head{
        position: absolute;
        margin-left: 0%;
        margin-top: 16%;
        color: darkorange;
        background-color: #030334;
        width: 100%;
        text-align: center;
        padding: 20px;
       
    }
    
        
    }
