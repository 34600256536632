
.container-body1{
   display: flex;
   gap: 30px;
   margin-top: 45px;
   justify-content: center;
}
.body1-first,
.body1-second,
.body1-third
{
 /* background-color: darkorange; */
 width: 350px; 
 
   
}


.eke:hover{
    text-decoration: underline;
}
.ekene:hover{
    text-decoration: underline; 
}
.ekeneth:hover{
    text-decoration: underline;
}
.first-bodyimage,
.second-bodyimage,
.third-bodyimage{
   width: 420px;
   height: 450px;
   position: relative;
}
.eke,.ekene,.ekeneth
{
    position: absolute;
    margin-bottom: 0px;
    z-index: 2;
    height: 450px;
    width: 420px;
    text-align: center;
    padding-top: 200px;
    color: white;
    cursor: pointer;
    
}
.ekeneth:hover{
background-color: rgba(15, 14, 14, 0.678);
}
.eke:hover{
    background-color:rgba(15, 14, 14, 0.678);
}
.ekene:hover{
    background-color:rgba(15, 14, 14, 0.678);
}
.top-stories{
    text-align: center;
    color: darkorange;
    padding-top: 20px;
}
.img2{
    width: 150px;
    height:150px ;
    /* margin-left: 10%; */
    border-radius: 20px;
    box-shadow: 0px 1px 3px 1px whitesmoke;
   background-color: #bbbbbb;
   border: #010647 solid;
   margin-top:20px ;
   
}
.img2-skeleton{
    width: 150px;
    height:150px ;
    /* margin-left: 10%; */
    border-radius: 20px;
    box-shadow: 0px 1px 7px 1px whitesmoke;
    animation: skeleton-loading 1s linear infinite alternate;
    background-color: #bbbbbb; 
}
.trynight{
    width: 50px;
    height:50px ;
    /* margin-left: 10%; */
    border-radius: 20px;
    box-shadow: 0px 1px 7px 1px whitesmoke;
    animation: skeleton-loading 1s linear infinite alternate;  
}

.body2-container{
    
    background-color: black;
    position: relative;
    display:flex ;
    max-width: 100%;
    margin-top: 0% !important;
    background-image: url("../images/newmic4.jpg");
     background-size: cover;
         
    
}
/* @media(max-width:1376px){
.body22{
gap: 20%;
} */

.program-header{
     margin-left: -67%; 
    padding: 5px;
    color:white;
    width: 225px;
     position: absolute; 
    z-index: 1;
    margin-top:8px;
    /* background-color: rgba(0, 0, 0, 0.295); */
    border-radius: 10px;
    
}
.body22{
    display: flex;
    gap: 20%;
    margin-left: 86% !important;
    /* position: absolute; */
    margin-top: 10%;
    
    
    
    
    
}
.body2new-container{
    margin-top: 20px;

}

.schedule{
         /* position: absolute;  */
        
        margin-left: 80% !important;
        cursor: default;
        font-size: 25px;
        font-weight: bold;
        margin-top: -50px;
        background: blue;
        background: linear-gradient(to left, darkorange 0%, #d8e1ee 25%, white 50%, #FFFFFF 75%, darkorange 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }
    .schedule:hover{
        text-decoration: underline !important;
    }
.listen2{
    border: solid white;
    height: 25px;
    padding: 5px;
    margin-top: 10px;
    padding-right: 30px;
    position: relative;
    cursor: default;
    margin-left: 270px;
    text-decoration: none;
    color: black;

}
.listen2:hover{
background-color: rgba(0, 0, 0, 0.11);
}
.icon-play{
    position: absolute;
    padding-top: 4px;
    padding-left: 8px;
    color: red;
    
}
.lnight{
 
   /* position: absolute; */
  
   /* margin-left: 14%; */
   font-weight: bold;
   color: white ; 
   text-decoration: none;
   margin-top: 50px;
  
   width: 150px;
   
}

.body3{
    position: relative;
    color: black;
}
.ltime{
    /* position:absolute ; */
    /* top: 65%; */
    /* padding: 30px; */
    /* margin-left: 12.2%; */
    color: white;
    
    width: 150px;

    
}
.wname{
    margin-left: 32%;
    padding-bottom: 5px;
}

.body2line-mobile{
   font-weight: bold; 
}
.body2Line{display: none;}
.backendbody2{
    z-index: 2;
    margin-left: 0 !important;
}
.lday{
    position: absolute;
    
    margin-left:230px;
    cursor: pointer;
    font-weight: bold;
    padding: 5px;


}

.day-time{
    position: absolute;
    margin-left: 230px;
    margin-top: 60%;
    padding: 5px;

}
.lcontainer{
     
    /* width: 100px; */
    margin-top: -15%;
    background-color:#072146;
    border-radius: 5px;

}

.lcontain-skeleton{
     
    width: 100px;
    margin-top: -15%;
 /* margin-left: 10%; */

    box-shadow: 0px 1px 7px 1px whitesmoke;
    animation: skeleton-loading 1s linear infinite alternate; 
    

}


.network-errors{
    text-align: center;
    justify-content: center;
}

/* loader animation */
.main-item {
    padding: 10px;
    color: white;
    width: 1300px !important;
  }
  
  .background-masker {
    background-color: #fff;
    position: absolute;
  }
  
  .btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
    width: 5%;
  }
  
  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
  
  .animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
  }
  
  .static-background {
    background-color: #f6f7f81c;
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    margin-bottom: 20px;
  }
  
  .shared-dom {
    width: 800px;
    height: 110px;
  }
  .sub-rect {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    float: left;
    margin: 20px 20px 20px 0;
  }
  .pure-background {
    background-color:darkorange;
  }
  
  .css-dom:empty {
    width: 280px;
    height: 220px;
    border-radius: 6px;
    box-shadow: 0 10px 45px rgba(0, 0, 0, .2);
    background-repeat: no-repeat;
    
    background-image:
      radial-gradient(circle 16px, lightgray 99%, transparent 0),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(#fff, #fff); 
    
    background-size:
      32px 32px,
      200px 32px,
      180px 32px,
      230px 16px,
      100% 40px,
      280px 100%;
    
    background-position:
      24px 30px,
      66px 30px,
      24px 90px,
      24px 142px,
      0 180px,
      0 0;
  }
  .body2-secondcontainer{
   
   display: flex;  
   width: 600px;
   height: 600px;
   position: relative;
   margin-left: 77% !important; 
   margin-right: auto;
   gap: 20%;

    
  }
  .first-body2container{
    position: relative;
    align-items: center;

    justify-content: center;
    width: 100%;
    display: flex;
   
    
  }


  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  
  .skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .skeleton-text__body {
    width: 75%;
  }
  
  .skeleton-footer {
    width: 30%;
  }
  .skeletonbody2-contain{
    display: flex;
   
    gap: 15%;
  }
   @keyframes skeleton-loading {
    0% {
      background-color: darkgrey;
    }
    50%{
        background-color: whitesmoke;  
    }
    100% {
      background-color: white;
    }
  }

.newabout-container{
  background-color: rgba(5, 5, 134, 0.384);
  width: 100%;
  justify-content: center;
  transform: skew(-14deg);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ebeonadiname-favorite{
  color:#060683 ;
  background-color: white;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  font-family: monospace;
  font-weight: bold;
 

}
.ebeonadiname-body{
   background:white; 
  background: linear-gradient(to left, white 0%,darkorange 25%, white 50%, white 75%, white 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px !important;
  color: white;
  font-weight: bold;
}
  .textdisplay-body2 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    max-width: 40ch;

    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    font-size: 25px;
    color: white;
    padding: 20px;
   
  }
  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }
  
  .textdisplay-body2 span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }
  
  .textdisplay-body2 span:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(3) {
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(4) {
    animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(5) {
    animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;

  }
  
  .textdisplay-body2 span:nth-child(6) {
    animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(7) {
    animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(8) {
    animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(9) {
    animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  span:nth-child(10) {
    animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(11) {
    animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(12) {
    animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(13) {
    animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(14) {
    animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(15) {
    animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(16) {
    animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(17) {
    animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  .textdisplay-body2 span:nth-child(18) {
    animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 7px !important;
  }
  
  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

.displaytextcover{
  margin-left: 15%;
} 




/* CSS */
.button-56 {
  align-items: center;
  background-color: darkorange;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-56:after {
  background-color: #030334;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}
.removebody2btn-nav{
  text-decoration: none !important;
}


 



/* CSS */
.button-73 {
  appearance: none;
  background-color: #FFFFFF;
  border-radius: 40em;
  border-style: none;
  box-shadow: #ADCFFF 0 -12px 6px inset;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  font-family: -apple-system,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: -.24px;
  margin: 0;
  outline: none;
  padding: 1rem 1.3rem;
  quotes: auto;
  text-align: center;
  text-decoration: none;
  transition: all .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: 7%;
  
}

.button-73:hover {
  background-color: #FFC229;
  box-shadow: #FF6314 0 -6px 8px inset;
  transform: scale(1.125);
}

.button-73:active {
  transform: scale(1.025);
}

@media (min-width: 768px) {
  .button-73 {
    font-size: 1.5rem;
    padding: .75rem 2rem;
   
  }
}




  /* .body4{
    background-color: #bbbbbb;
    width: 150px !important;
    height: 150px !important;

  } */

  @media(max-width:1800px){
    .body2-secondcontainer{
    display: flex;  
   width: 600px;
   height: 600px;
   position: relative;
   margin-left: 50.5% !important;
   margin-right: auto;
   gap: 20%;

    }
  }

@media(max-width:1400px){
    .body2-secondcontainer{
    display: flex;  
   width: 600px;
   height: 600px;
   position: relative;
   margin-left: 25% !important;
   margin-right: auto;
   gap: 20%;

    }
  }
@media(max-width:993px){
    .body2-secondcontainer{
        margin-left: 10px ;
        display: grid; 
        grid-template-columns: auto auto;
        padding: 30px;
     } 
     .body22{
        margin-left: 70%;
     } 
     .show-time{
        margin-top: -20px;
        left:1% !important ;
     } 
     .main-item {
        padding: 10px;
        width: 700px !important;
      }
      .body2-container{
       
      

      }
      .first-firstnav{
        margin-left: -21% !important;
      }
      .skeletonbody2-contain{
        display: grid;
        grid-template-columns: auto auto;
      }
      .newabout-container{
        background-color: rgba(5, 5, 134, 0.384);
        width: 100%;
        justify-content: center;
        transform: skew(0deg);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-left: -5%;
      }

      .ebeonadiname-body{
        background:white; 
       background: linear-gradient(to left, white 0%,darkorange 25%, white 50%, white 75%, white 100%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       font-size: 55px !important;
       color: white;
       text-align: center;
       font-weight: bold;
     }

     .ebeonadiname-favorite{
      color:#060683 ;
      background-color: white;
      text-align: center;
      font-size: 20px;
      padding: 10px;
      font-family: monospace;
      font-weight: bold;
      margin-left: 5%;
      width: 300px;
    
    
    }
     
}
@media(min-width:993px){
    .body2-secondcontainer{
        display: flex; 
        margin-left: 15%;  
        text-align: center;
        padding: 20px;
        height: 30%;
         
        
    }  
    .lcontainer{
        left: 40%;
    }
    
    
    
}


/* mobile responsive */
@media(max-width:680px){
    .body2-secondcontainer{
        display: grid;
        margin-top: 85px;
        
        }
        .body3{
            position: relative;
        }
        .body4 {
            margin-top: -60px;
            
        }
        .body5{
            margin-top: -60px;   
        }
        .body6{
            margin-top: -60px;     
        }
        .img2{
            width: 150px;
            height: 150px;
        }
        .body2-container{
           
            
        }
        .program-header{
            /* margin-left: -60px; */
            margin-top: -39px;
            width: 100%;
            margin-left: -32%;
            border-radius: 0;
            text-align: center;
            background-color: black;

        }
        .listen2{
            display: none;
        }
        .first-night{
            margin-left: -40px;
            
        }
        .day-time{
            top: -30px;
        }
        .lnight{
            margin-left: 30px;
            margin-top: 30%;
        }
        .ltime{
            margin-left: 15px;
        }
        .show-time{
           margin-top: -20px;
        }

        .backendbody2{
            display: flex;
           
        }
        /* .body2-container{
            height:750px ;
        } */

        

}


/* mobile responsive */
@media(max-width:680px){
    .container-body1{
        display: grid;
    }
    
    .first-bodyimage,
    .second-bodyimage,
    .third-bodyimage{
       max-width: 350px;
       max-height: 350px;
       position: relative;
       top: 20px;
    }
    .body1-first,
    .body1-second,
    .body1-third{
        max-width: 350px;
        margin-top: 20px;
        
    }
    .container-body1{
        margin-left: 2px;
    }
    .eke,.ekene,.ekeneth{
        max-height: 150px;
        max-width: 350px;
        text-align: center;
        margin-top: 0px;  
    }
    .lcontainer{
        margin-left: -40px;
        width: 200px;
        background-color: none ;
       
      
       
    }
    .ltime{
        margin-top: 40px;
    }
    .first-body2container{
        width: 200px;
        row-gap: 20px;
    }
    .img2{
        margin-top: 20px;
        width: 100px;
        height: 100px;
    }
    .body22{
        margin-left: -7px;
    }
    .schedule{
        /* margin-left: 65%; */
        /* text-align: center; */

    }
    
    .body2new-container{
        margin-top: 5px;
    }
    .body2line-mobile{
        display: block;
        color: black;
        border-top: 1px dotted red;
    }
    .body2Line{
        display: block;
        height: 5px;
        width: 100%;
        margin-left: -80%;
        margin-top: 30%;
        
    }
   
    }
    @media(max-width:680px){
        .body2-secondcontainer{
           margin-left: -10% ;
           display: grid; 
           grid-template-columns: auto;
           padding: 20px;
        }
       .lcontainer{
        margin-left: 3%;
        
        }
        .lnight{
            margin-top: 50%;
            margin-left: 5%;
            
        }
        .body2-container{
            /* height:986px !important; */
             display: block; 
        }
        .schedule{
          
            margin-left: -53% !important;
            justify-content: center;
            margin-top: 20%;
           
        }
        .main-item {
            padding: 10px;
            color: white;
            width: 300px !important;
          }
       .skeletonbody2-contain{
        display: grid;
        grid-template-columns: auto !important;
       } 
       .img2-skeleton{
        margin-top: 50px;
        width: 100px;
        height: 100px;

       }
       .lcontain-skeleton{
     
        width: 100px;
        margin-top: 5%; 
        margin-left: 10%; 
        height: 50px;
        border-radius: 10px;
        box-shadow: 0px 1px 7px 1px whitesmoke;
        animation: skeleton-loading 1s linear infinite alternate; 
        
    
    } 
    .first-body2container{
      position: relative;
      align-items: center;
  
      justify-content: center;
      width: 100%;
      display: grid;
     
      
    } 
    .ebeonadiname-body{
      background:white; 
     background: linear-gradient(to left, white 0%,darkorange 25%, white 50%, white 75%, white 100%);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-size: 55px !important;
     color: white;
     text-align: center;
     font-weight: bold;
   }
   .newabout-container{
    background-color: rgba(5, 5, 134, 0.384);
    width: 100%;
    justify-content: center;
    transform: skew(0deg);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-left: -8%;
  }
  .textdisplay-body2 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    max-width: 40ch;

    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    font-size: 18px;
    color: white;
    padding: 20px;
   
  }
  .button-73{
    position: relative;
    margin-left: 25%;
  }
  .ebeonadiname-favorite{
    color:#060683 ;
    background-color: white;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    font-family: monospace;
    font-weight: bold;
    margin-left: 10%;
    width: 300px;
  
  
  }
   
    }
     

