.monday-div {
    display: flex;
    padding: 50px;
    width: 60%;
    margin-left: 3.5%;

    gap: 15px;

}

.monday-div-ske {
    display: flex;
    padding: 50px;
    width: 26%;
   
    /* background-color: aliceblue; */

    gap: 15px;
}


.monday-time {
    margin-left: 5.6%;
    background-color: black;
    color: white;
    padding: 3px;
    border-top-right-radius: 20px;
    margin-top: 20px;
    position: absolute;
}

.vl {
    border-left: 6px solid rgba(13, 13, 87, 0.877);
    height: 30%;
    position: absolute;
    left: 63%;
    padding: 30px;
    display: none;

}

.monday1-image {
    width: 300px;
    height: 180px;
    top: 0px;

    border: solid;
    border-radius: 10px;
    border-top-left-radius: 0;
}

.monday1-image-ske {
    width: 200px;
    height: 200px;

    background-color: #f6f7f8;
    /* border: solid; */
    border-radius: 100px;

    animation: skeleton-loading 1s linear infinite alternate;
}

.monday-skeleton {

    width: 70% !important;
    background-color: #DDDBDD;
    margin-left: 0%;


}

.monday-skeleton-all {

    width: 70% !important;
    background-color: #DDDBDD;
    margin-left: -25%;


}

.p-skeletons {
    width: 200%;
    height: 10%;
    background-color: #f6f7f8;
    animation: skeleton-loading 1s linear infinite alternate;
}

.errorssample {
    margin-left: 50%;
}

.monday-all {
    display: flex;
    margin-top: 100px;
    /* background-color: aquamarine; */
    width: 100%;
}

.underline {
    width: 61.5%;
    margin-left: 30px;
}

.monday-article {
    font-size: 18px;

    /* text-align: center; */
    justify-content: center;
    width: 2000px !important;
    padding: auto;
    cursor: pointer;
    margin-left: 30px;

}

.readmorebutton {
    color: red;
}

.schedule-title {

    margin-left: 16%;
    margin-top: 0px;
    color: rgb(5, 5, 190);
    width: 750px;
}

.schedule-title-ske {
    margin-left: 16%;
    margin-top: 0px;

    width: 750px;
}


.control-monday {
   justify-content: center;
    margin-top: 30px;
}

.dayname {
    color: #060683;
}

/* .schedule-imageframe{

} */

.loaderless {
    text-align: center;
    /* margin-left: 20%; */
}

figure {
    /* position: absolute; */
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 6.250em;
    height: 6.250em;
    animation: rotate 2.4s linear infinite;
    margin-left: 35% !important;
}

.white {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    animation: flash 2.4s linear infinite;
    opacity: 0;
}

.dot {
    position: absolute;
    margin: auto;
    width: 2.4em;
    height: 2.4em;
    border-radius: 100%;
    transition: all 1s ease;
}

.dot:nth-child(2) {
    top: 0;
    bottom: 0;
    left: 0;
    background: #FF4444;
    animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(3) {
    left: 0;
    right: 0;
    top: 0;
    background: #FFBB33;
    animation: dotsX 2.4s linear infinite;
}

.dot:nth-child(4) {
    top: 0;
    bottom: 0;
    right: 0;
    background: #99CC00;
    animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(5) {
    left: 0;
    right: 0;
    bottom: 0;
    background: #33B5E5;
    animation: dotsX 2.4s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    10% {
        width: 6.250em;
        height: 6.250em;
    }

    66% {
        width: 2.4em;
        height: 2.4em;
    }

    100% {
        transform: rotate(360deg);
        width: 6.250em;
        height: 6.250em;
    }
}

@keyframes dotsY {
    66% {
        opacity: .1;
        width: 2.4em;
    }

    77% {
        opacity: 1;
        width: 0;
    }
}

@keyframes dotsX {
    66% {
        opacity: .1;
        height: 2.4em;
    }

    77% {
        opacity: 1;
        height: 0;
    }
}

@keyframes flash {
    33% {
        opacity: 0;
        border-radius: 0%;
    }

    55% {
        opacity: .6;
        border-radius: 100%;
    }

    66% {
        opacity: 0;
    }
}

.monday-container {
    box-sizing: border-box;
}

.loader-scheduled {
    margin-left: 25%;
}

.divloader {
    margin-left: 50%;
}

.main-scheduleskeleton {
    width: 60% !important;
    background-color: #FF4444;
}

/* program style */

.projcard-container {
    margin: 50px;
    display: block;
    position: relative;
}


.projcard-container,
.projcard-container * {
    box-sizing: border-box;
}

.projcard-container {
    /* margin-left: 40%; */
    margin-right: auto;
    width: 100%;




}

.projcard {
    position: relative;
    width: 60%;
    /* height: 300px;  */
    margin-bottom: 40px;
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #ddd;
    font-size: 18px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, .66);
    transition: box-shadow 0.2s ease, transform 0.2s ease;
    margin-left: 2%;
}

.projcard:hover {
    box-shadow: 0 34px 32px -33px rgba(0, 0, 0, .18);
    transform: translate(0px, -3px);
}

.projcard::before {
    content: "";
    /* position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
    opacity: 0.07;
}

/* .projcard:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #424242, transparent 50%);
} */
.projcard-innerbox {
    /* position: absolute;   */

    display: flex;

}

.projcard-img {
    /* position: absolute; */
    height: 130px !important;
    width: 130px !important;
    z-index: 1 !important;
    transition: transform 0.2s ease;
    border-radius: 100px !important;
    border-bottom-width: 20px solid;
    margin-top: 5%;
    border: #424242 solid;

    margin-left: 1%;
}

.projcard:nth-child(2n) .projcard-img {
    left: initial;
    right: 0;
}

.projcard:hover .projcard-img {
    transform: scale(1.05) rotate(1deg);
}

.projcard:hover .projcard-bar {
    width: 70px;
}

.projcard-textbox {

    top: 7%;
    bottom: 7%;
    padding: 5px;
    font-size: 17px;

}
.projcard projcard-blue{
    cursor: default !important;
}

.projcard-textbox::before,
.projcard-textbox::after {
    content: "";
    position: absolute;
    display: block;
    background: #ff0000bb;
    background: #fff;
    top: -20%;
    left: -55px;
    /* height: 140%; */
    width: 60px;
    transform: rotate(8deg);
}

.projcard-textbox::after {
    /* display: none; */
    left: initial;
    right: -55px;
}

.projcard-textbox * {
    position: relative;
}

.projcard-title {
    font-family: 'Voces', 'Open Sans', arial, sans-serif;
    font-size: 24px;
}

.projcard-subtitle {
    font-family: 'Voces', 'Open Sans', arial, sans-serif;
    color: #888;
}

.projcard-bar {
    left: -2px;
    width: 50px;
    height: 5px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
}

.projcard-blue .projcard-bar {
    background-color: #060683;
}

.projcard-blue::before {
    background-image: linear-gradient(-70deg, #0088FF, transparent 50%);
}

.projcard-blue:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #0088FF, transparent 50%);
}

.projcard-red .projcard-bar {
    background-color: #D62F1F;
}

.projcard-red::before {
    background-image: linear-gradient(-70deg, #D62F1F, transparent 50%);
}

.projcard-red:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #D62F1F, transparent 50%);
}

.projcard-green .projcard-bar {
    background-color: #40BD00;
}

.projcard-green::before {
    background-image: linear-gradient(-70deg, #40BD00, transparent 50%);
}

.projcard-green:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #40BD00, transparent 50%);
}

.projcard-yellow .projcard-bar {
    background-color: #F5AF41;
}

.projcard-yellow::before {
    background-image: linear-gradient(-70deg, #F5AF41, transparent 50%);
}

.projcard-yellow:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #F5AF41, transparent 50%);
}

.projcard-orange .projcard-bar {
    background-color: #FF5722;
}

.projcard-orange::before {
    background-image: linear-gradient(-70deg, #FF5722, transparent 50%);
}

.projcard-orange:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #FF5722, transparent 50%);
}

.projcard-brown .projcard-bar {
    background-color: #C49863;
}

.projcard-brown::before {
    background-image: linear-gradient(-70deg, #C49863, transparent 50%);
}

.projcard-brown:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #C49863, transparent 50%);
}

.projcard-grey .projcard-bar {
    background-color: #424242;
}

.projcard-grey::before {
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
}

.projcard-grey:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #424242, transparent 50%);
}

.projcard-customcolor .projcard-bar {
    background-color: var(--projcard-color);
}

.projcard-customcolor::before {
    background-image: linear-gradient(-70deg, var(--projcard-color), transparent 50%);
}

.projcard-customcolor:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, var(--projcard-color), transparent 50%);
}

.projcard-description {
    z-index: 10;
    font-size: 15px;
    color: #424242;
    /* height: 125px; */
    /* overflow: hidden; */
    text-overflow: ellipsis;
    padding: 10px;
    cursor: default !important;
}

.projcard-tagbox {
    /* position: absolute;  */
    bottom: 1%;
    font-size: 14px;
    cursor: default;
    user-select: none;
    pointer-events: none;
}

.projcard-tag {
    display: inline-block;
    background: #E0E0E0;
    color: #777;
    border-radius: 3px 0 0 3px;
    line-height: 26px;
    padding: 0 10px 0 23px;
    position: relative;
    margin-right: 20px;
    cursor: default;
    user-select: none;
    transition: color 0.2s;
    margin-left: 2%;

}

.projcard-tag::before {
    content: '';
    position: absolute;
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    height: 6px;
    left: 10px;
    width: 6px;
    top: 10px;
}

.projcard-tag::after {
    content: '';
    position: absolute;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #E0E0E0;
    border-top: 13px solid transparent;
    right: -10px;
    top: 0;
}

.fromprogram {
    margin-left: 1.3%;
    color: #060683;
    ;
}

.toprogram {
    color: darkorange;
}





/* CSS */
.button-30 {
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
    z-index: 2;
    margin-left: 5px;
}

.button-30:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
}

.button-30:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

.schedule-date {
    margin-left: 5%; 
    /* text-align: center !important; */
}

@media(max-width:1400px) {
    .loader-scheduled {
        margin-left: 200%;
    }

    .monday-skeleton {
        /* margin-left: 30%; */
        width: 80%;
        background-color: #DDDBDD;
        display: block;

    }
}

/* ipad responsive */
@media(max-width:993px) {
    .vl {
        display: none;
    }

    .schedule-title {
        margin-left: 20px;
        width: 700px;
        text-align: center;
    }

    .monday-article {

        width: 800px !important;
    }

    .control-monday {
        overflow-x: hidden;
    }

    .monday-time {
        margin-left: 12%;
    }

    .monday-skeleton {
        display: none;


    }
}

/* mobile responsive */
@media(max-width:680px) {
    .monday-all {
        display: grid;
    }

    .monday-div {
        display: grid;
        margin-top: 80px;
    }

    .vl {
        display: none;
    }

    .monday-article {

        /* margin-left: 35% !important; */
        width: 300px;

    }

    .schedule-title {
        position: absolute;
        margin-top: 50px;
        margin-left: 34%;
        font-size: 15px;
        width: 300px;
        text-align: center;
        justify-content: center;


    }

    .control-monday {
        justify-content: center; 
    }

    .monday1-image {
        width: 200px;
        height: 200px;
        margin-left: 60% !important;
        top: -20px;
        display: block;


    }

    .schedule-date {

        font-size: 25px;

        /* width: 350px; */
        text-align: center;
        justify-content: center;
        /* display: none; */
        margin-left: -5%;


    }

    .underline {
        width: 100% !important;
        display: block;
        /* margin-left: 25%; */


    }

    .monday-container {
        /* margin-left: -25%; */

    }

    .monday-time {
        margin-left: 48.8% !important;
        top: 130px;
        position: relative;

    }

    .monday-article {
        font-size: 18px;
        margin-left: 40% !important;
        width: 145% !important;

    }

    .loader-scheduled {
        margin-left: 200%;
    }

    .monday-skeleton {
        display: none;
    }

    .projcard {

        position: relative;
        width: 87%;
        border-radius: 10px;
        background-color: #fff;
        border: 2px solid #ddd;
        font-size: 18px;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 4px 21px -12px rgba(0, 0, 0, .66);
        transition: box-shadow 0.2s ease, transform 0.2s ease;
        margin-left: -4%;

    }

    .projcard-innerbox {
        display: grid;
    }

    .projcard-img {

        height: 100px;
        width: 100px;
        z-index: 3;
        transition: transform 0.2s ease;
        border-radius: 100px;
        border-bottom-width: 20px solid;
        margin-top: 5%;
        margin-left: 31%;
        border: #424242 solid;

    }

    .projcard-title {
        font-family: 'Voces', 'Open Sans', arial, sans-serif;
        font-size: 24px;
        text-align: center;
    }

    .projcard-subtitle {
        font-family: 'Voces', 'Open Sans', arial, sans-serif;
        color: #888;
        text-align: center;
    }

    .projcard-textbox {
        top: 7%;
        bottom: 7%;
        font-size: 17px;

    }

    .projcard-container {
        margin-top: 0%;
        width: 400px !important;
        margin-left: 7% !important;
    }

    .monday-div-ske {

        display: flex;
        padding: 50px;
        width: 50%;
        margin-left: 3.5%;
        /* background-color: aliceblue; */
        gap: 15px;
    }


}