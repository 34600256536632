.link-re-removeEco{
    color: black;
    text-decoration: none;
}
.link-re-removeEco:hover{
    text-decoration: none;
}
#displayEcom{
    margin-top: 6%; 
    background-color: white;
    padding: 0;
     width: 90%;
     margin-left: 5%; 
   

}
#fashion-title{
    text-align: center;
}
.controlinput-numM{
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: #CED4DA solid 1px;
  display: block;
  
}
.body_template{
   
   justify-content: center;
   text-align: center;
   
}
.tikTakTest{
  position: absolute;
  z-index: 500 !important;
  width: 780px;

}

.innerBody-template{
    background-color:#FFFFFF;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
    border-color: white;
    text-align: center;
    
    padding-top: 5%;
   margin-left:15% ;
   margin-right:15% ;
   
}
.Template_img{
    width: 20%;
}
.userName-emailTemp{
    margin-top: 5%;
}
.comment_divS{
  background-color:#F1F1F2;
  padding: 10px;
}



.button-66 {
background-color: #0a6bff;
  border-radius: 4px;
  margin-top: 5%;
  box-shadow: rgba(1,60,136,.5) 0 -1px 3px 0 inset,rgba(0,44,97,.1) 0 3px 6px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  /* display: inherit; */
  font-family: "Space Grotesk",-apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
 
  min-height: 56px;
  min-width: 120px;
  touch-action: manipulation;
 
  transition: all .2s cubic-bezier(.22, .61, .36, 1); 
}
.submitOtp{
  background-color: #065dd8;
  text-align: center;
  margin-top: 2%;
  padding: 5px;
  width: 275px;
  margin-left: 23%;
  color: white;
}

.submitOtp:hover{
     background-color: #0750b6;
}

.button-66:hover {
  background-color: #065dd8;
  transform: translateY(-2px);
}
.iconBackBtn{
  position: absolute;
  margin-left: 90%;
  border: 3px solid #030334 ;
  background-color: white;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  border-radius: 50px;
}

.downItem-div:hover{
  
 
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow:0 0 15px 5px #ccc;
 
  display: flex;
  
}
.cutomerFeedd{
  padding: 10px;
}
.cutomerFeedd-feed{
  padding: 5px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.235);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.235);

}
.starREviewName{
  padding: 10px;
}


.side-controlExtra{
  padding: 3px;
}

.trowText{
  padding: 5px;
  font-weight: bold;
  width: 300px;
}
.imgNameSide{
  width: 120px;
  height: 120px;
}
.antialiased{
  /* display: block; */
  padding: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.235);
  display: inline-block;
  cursor: default;
 
}
.downItem-div{
  display: flex;
  padding: 5px;
 
}
.central-CoverLine{
  border: 0.5px solid rgba(0, 0, 0, 0.235);
  width: 35%;

}
.selling_Tittle{
  color: darkorange;
  font-weight: bold;
  padding: 5px;
}
.hidEShow:hover{
  color: darkorange;
}
.videoDiv-display{
 
  justify-content: center !important;
  text-align: center;
}
.DisplayVD{
  justify-content: center;
  text-align: center;
}
.display-Outofstock{
  background-color:#5CB85C;
  color: white;
  padding: 3px;
  position: absolute;
  box-shadow:0 0 15px 5px #ccc;
  border-radius: 3px;
}
.nameDisplay_account{
  position: absolute;
  margin-left: 5%;
  cursor: pointer;
  background-color: #030334;
  color: white;
  padding: 12px;
  z-index: 100;

}
.displayAllItems{
  display: none;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 





@media (min-width: 768px) {
  .button-66 {
    padding: 16px 44px;
    min-width: 150px;
  }
}


@media(max-width: 993px){

  .central-CoverLine{
    width: 100%;
  }
}


.Btn_tempDiv{
  
    justify-content: center;
    text-align: center;
}
.reuestPassw_text{
    margin-top: 5%;
}

@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');


.ulA-tag li a{
    text-decoration: none; 
}

.ulA-tag li {
    list-style: none outside none;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    
}

.socialbtns .fa {
  width: 40px;
    height: 28px;
  color: #000;
  background-color: #FFF;
  border: 1px solid #000;
  padding-top:5px;
  border-radius: 22px;
  -moz-border-radius: 22px;
  -webkit-border-radius: 22px;
  -o-border-radius: 22px;
}

.socialbtns .fa:hover {
  color: #ffffff;
  background-color: #000;
  border: 1px solid #000;
}
.otpDiv{
 
  justify-content: center !important;
  text-align: center !important;
  padding-left: 15%;
 
  
}
.ebeInput{
  width: 50px !important;
  text-align: center !important;
  justify-content: center !important;
  height: 50px;
 
}
.otpShift-base{
  margin-left: 40% !important;
}
.countOtp{
  text-align: center;
  font-size: larger;
  font-weight: bold;
  color: green;
}
.noHistory-text{
 
  color: red;
}

.didtnGet{
  text-align: center;
  padding: 10px;
}
.btnDidtnGet{
  background-color: rgba(46, 46, 84, 0.983);
  
  color: white;
}
.btnDidtnGet:hover{
  background-color:midnightblue;
  
  color: white;
}
.succMeesage{
  color: green;
}




.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background:darkorange;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.imageFlexAdded{
  display: flex !important;
  gap: 2%;
}







.receipt-content{
  padding-left: 20%;
  padding-right: 20%;
}
.receipt-content .logo a:hover {
  text-decoration: none;
  color: #7793C4; 
}

.receipt-content .invoice-wrapper {
  background: #FFF;
  border: 1px solid #CDD3E2;
  box-shadow: 0px 0px 1px #CCC;
  padding: 40px 40px 60px;
  margin-top: 40px;
  border-radius: 4px; 
  
}
.processing_Div{
 font-weight: lighter !important;
 font-size: medium;

}

.receipt-content .invoice-wrapper .payment-details span {
  color: #A9B0BB;
  display: block; 
}
.receipt-content .invoice-wrapper .payment-details a {
  display: inline-block;
  margin-top: 5px; 
}

.receipt-content .invoice-wrapper .line-items .print a {
  display: inline-block;
  border: 1px solid #9CB5D6;
  padding: 13px 13px;
  border-radius: 5px;
  color: #708DC0;
  font-size: 13px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; 
}

.receipt-content .invoice-wrapper .line-items .print a:hover {
  text-decoration: none;
  border-color: #333;
  color: #333; 
}
.print{
  cursor: pointer;
}

.receipt-content {
  background: #ECEEF4; 
}
@media (min-width: 1200px) {
  .receipt-content .container {width: 900px; } 
}

.receipt-content .logo {
  text-align: center;
  margin-top: 50px; 
}

.receipt-content .logo a {
  font-family: Myriad Pro, Lato, Helvetica Neue, Arial;
  font-size: 36px;
  letter-spacing: .1px;
  color: #555;
  font-weight: 300;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; 
}

.receipt-content .invoice-wrapper .intro {
  line-height: 25px;
  color: #444; 
}

.receipt-content .invoice-wrapper .payment-info {
  margin-top: 25px;
  padding-top: 15px; 
}

.receipt-content .invoice-wrapper .payment-info span {
  color: #A9B0BB; 
}

.receipt-content .invoice-wrapper .payment-info strong {
  display: block;
  color: #444;
  margin-top: 3px; 
}



@media(max-width:993px){

  .receipt-content{
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}







@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .payment-info .text-right {
  text-align: left;
  margin-top: 20px; } 
}
.receipt-content .invoice-wrapper .payment-details {
  border-top: 2px solid #EBECEE;
  margin-top: 30px;
  padding-top: 20px;
  line-height: 22px; 
}


@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .payment-details .text-right {
  text-align: left;
  margin-top: 20px; } 
}
.receipt-content .invoice-wrapper .line-items {
  margin-top: 40px; 
}
.receipt-content .invoice-wrapper .line-items .headers {
  color: #A9B0BB;
  font-size: 13px;
  letter-spacing: .3px;
  border-bottom: 2px solid #EBECEE;
  padding-bottom: 4px; 
}
.receipt-content .invoice-wrapper .line-items .items {
  margin-top: 8px;
  border-bottom: 2px solid #EBECEE;
  padding-bottom: 8px; 
}
.receipt-content .invoice-wrapper .line-items .items .item {
  padding: 10px 0;
  color: #696969;
  font-size: 15px; 
}
@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .items .item {
  font-size: 13px; } 
}
.receipt-content .invoice-wrapper .line-items .items .item .amount {
  letter-spacing: 0.1px;
  color: #84868A;
  font-size: 16px;
 }
@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .items .item .amount {
  font-size: 13px; } 
}

.receipt-content .invoice-wrapper .line-items .total {
  margin-top: 30px; 
}

.receipt-content .invoice-wrapper .line-items .total .extra-notes {
  float: left;
  width: 40%;
  text-align: left;
  font-size: 13px;
  color: #7A7A7A;
  line-height: 20px; 
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .total .extra-notes {
  width: 100%;
  margin-bottom: 30px;
  float: none; } 
}

.receipt-content .invoice-wrapper .line-items .total .extra-notes strong {
  display: block;
  margin-bottom: 5px;
  color: #454545; 
}

.receipt-content .invoice-wrapper .line-items .total .field {
  margin-bottom: 7px;
  font-size: 14px;
  color: #555; 
}

.receipt-content .invoice-wrapper .line-items .total .field.grand-total {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500; 
}

.receipt-content .invoice-wrapper .line-items .total .field.grand-total span {
  color: #20A720;
  font-size: 16px; 
}

.receipt-content .invoice-wrapper .line-items .total .field span {
  display: inline-block;
  margin-left: 20px;
  min-width: 85px;
  color: #84868A;
  font-size: 15px; 
}

.receipt-content .invoice-wrapper .line-items .print {
  margin-top: 50px;
  text-align: center; 
}



.receipt-content .invoice-wrapper .line-items .print a i {
  margin-right: 3px;
  font-size: 14px; 
}

.receipt-content .footer {
  margin-top: 40px;
  margin-bottom: 110px;
  text-align: center;
  font-size: 12px;
  color: #969CAD; 
}
.receipt-contD{
  padding-left: 20%;
  padding-right: 20%;
}                   

@media(max-width:1400px){
    #displayEcom{
        margin-top: 10%; 
        } 
.cardId-again{
    padding: 0% !important;
}

@media(max-width: 1100px){
  .tikTakTest{
   
   
    width: 700px;
  }
}


}

@media(max-width:993px){
  .iconBackBtn{
    display: none;
  }
  .tikTakTest{
   width: 625px;
  }
}



@media(max-width:640px){
    #displayEcom{
        margin-top: 25%; 
        } 
.otpDiv{
  padding-left: 8% !important;
  padding: 5px;
     } 
     .ebeInput{
      width: 40px !important;
     
      height: 40px;
     
    }
    .submitOtp{
    
      
    
      margin-left: 10%;
     
    } 
    .tikTakTest{
      width: 327px;
     }
    .antialiased{
      /* display: block; */
      
      border: 0.5px solid rgba(0, 0, 0, 0.235);
      width: 77%;
      margin-left: 11.5%;
      
      
    } 
    .central-CoverLine{
      border: 0.5px solid rgba(0, 0, 0, 0.235);
      width: 77%;
      margin-left: 11%;
    
    }
    .DisplayVD{
     width: 350px;
    }
    .display-Outofstock{
      margin-top: 0% !important;
    }

    .receipt-content{
      padding-left: -20% !important;
      padding-right: -20% !important;
    }
    #tableReceiptMobile{
      margin-left: -12% !important;
    }
    .hide_ViewProNav{
      display: none;
    }
    
     
    }


   
    
  