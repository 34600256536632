
.createcompany-contain{
    padding: 150px;
    padding-left: 250px;
    padding-right: 250px;
}
#booking-form-container-id{
    box-shadow:
    0 -2px 2px 0 rgba(199, 199, 199, 0.55),
    0 1px 1px 0 #fff,
    0 2px 2px 1px #fafafa,
    0 2px 4px 0 #b2b2b2 inset,
    0 -1px 1px 0 #f2f2f2 inset,
    0 15px 15px 0 rgba(41, 41, 41, 0.09) inset;
    padding: 10px;
    justify-content: center;
   
}
.TextsAreainput{
  width: 100%;
  border-radius: 5px;
  border: solid #CED4DA 1px;
  height: 100px ;
}

.imageControl-modal{
  width: 100px;
}

.displayOrder_alert{
  position: fixed !important;
  margin-top: 1.5% !important;
  z-index: 50 !important;
}
.submitbtn-create{
    text-align: center;
    margin-left: 40%;
    width: 20%;
    margin-top: 2%;
}
.filld-form-create{
    text-align: center;
    font-weight: bold;
    font-size: 25px;
}
.product-detailsinput{
    height: 100px;
}
.product-detailsinputad{
    height: 50px;
}
#delete-red{
    color: red;
}
.removeadd-product{
    margin-top: 5px;
}
.button-addimages{
    padding: 10px;
   margin-top:5px ;
    background-color:#030334 ;
    color: white;
    border-radius: 10px;

}
.input-imagesfile{
    margin-top: 5px;
}
.removeadd-product{
    padding: 5px;
    border-radius: 10px;
    background-color: #E9ECEF;
}
.deleteMarked{
    background-color: #030334;
    color: white;
    padding: 5px;
    width: 150px;
}
#orderTableId{
    margin-left: 5%;

}
#table-orderCheck{
    margin-top: 12%;
     padding-left: 14% !important;
    padding-right: 10% !important; 
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content; 
   
    overflow-x: auto;
    white-space: nowrap;
}
#completedOrderBtn{
    background-color: green !important;
    color: white;
}
.tableTgf{
    margin-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
   
}
.pagination-Content{
   position: absolute;
   

}
.side_iconBarDisplay{
  display: flex;
  gap: 2%;
}
.search_box {
    position: absolute;
    margin-top: 12%;
    margin-left: 14%;
  }
  
  .search {
    padding-left: 25px;
    display: flex;
    align-items: center;
    margin : auto;
    width : 612px;
    height: 54px;
    background-color : white;
   
    -webkit-box-shadow: 0px 10px 38px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 38px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 10px 38px 0px rgba(0,0,0,0.1);
  }
  
  .select_area {
    color : #4451FE;
    display : flex;
    align-items: center;
    font-family: 'Roboto', sans-serif; line-height:24px;
    font-weight : medium ;
  }
  
  .side_iconBarDisplayDiv:hover{
           color: darkorange;
  }
  .side_iconBarDisplayDivff{
    z-index: 50;
  }
  .Center_bodyRed{
    justify-content: center !important;
  }
 
  
  .line {
    margin-left: 15px;
    border-left: 1px solid #D8D8D8;
    height: 40px;
  }
  
  .search_text {
    width: 100%;
    border: none;
    margin-left : 15px;
    font-size : 16px;
    font-family: 'Roboto', sans-serif; line-height:24px;
   
  }
  
  ::-webkit-input-placeholder { 
    color: #ADB1B8;
  }
  
  
  .text_and-icon {
    width: 410px;
    display: flex;
    align-items: center;
  }
  .search_icon {
    color : #5E6573;
      transition: all .6s ease-in-out;
      -webkit-transition: all .6s ease-in-out; /** Chrome & Safari **/
      -moz-transition: all .6s ease-in-out; /** Firefox **/
      -o-transition: all .6s ease-in-out; /** Opera **/
  }
  
  .search_text:focus {
    outline: none !important;
  }
  
  .search_text:focus + .search_icon{
    color : #4451FE;
    transform: translate(55px,0);
      -webkit-transform: translate(55px,0); 
      -o-transform: translate(55px,0); 
      -moz-transform: translate(55px);
  }


/* alert style */
.alert>.start-icon {
  margin-right: 0;
  min-width: 20px;
  text-align: center;
}

.alert>.start-icon {
  margin-right: 5px;
}

.greencross
{
font-size:18px;
    color: #25ff0b;
  text-shadow: none;
}

.alert-simple.alert-success
{
border: 1px solid rgba(36, 241, 6, 0.46);
  background-color: rgba(7, 149, 66, 0.12156862745098039);
  box-shadow: 0px 0px 2px #259c08;
  color: #0ad406;
text-shadow: 2px 1px #00040a;
transition:0.5s;
cursor:pointer;
}
.alert-success:hover{
background-color: rgba(7, 149, 66, 0.35);
transition:0.5s;
}
.alert-simple.alert-info
{
border: 1px solid rgba(6, 44, 241, 0.46);
  background-color: rgba(7, 73, 149, 0.12156862745098039);
  box-shadow: 0px 0px 2px #0396ff;
  color: #0396ff;
text-shadow: 2px 1px #00040a;
transition:0.5s;
cursor:pointer;
}

.alert-info:hover
{
background-color: rgba(7, 73, 149, 0.35);
transition:0.5s;
}

.blue-cross
{
font-size: 18px;
  color: #0bd2ff;
  text-shadow: none;
}

.alert-simple.alert-warning
{
    border: 1px solid rgba(241, 142, 6, 0.81);
  background-color: rgba(220, 128, 1, 0.16);
  box-shadow: 0px 0px 2px #ffb103;
  color: #ffb103;
  text-shadow: 2px 1px #00040a;
transition:0.5s;
cursor:pointer;
}

.alert-warning:hover{
background-color: rgba(220, 128, 1, 0.33);
transition:0.5s;
}

.warning
{
    font-size: 18px;
  color: #ffb40b;
  text-shadow: none;
}

.alert-simple.alert-danger
{
border: 1px solid rgba(241, 6, 6, 0.81);
  background-color: rgba(220, 17, 1, 0.16);
  box-shadow: 0px 0px 2px #ff0303;
  color: #ff0303;
  text-shadow: 2px 1px #00040a;
transition:0.5s;
cursor:pointer;
}

.alert-danger:hover
{
   background-color: rgba(220, 17, 1, 0.33);
transition:0.5s;
}

.danger
{
    font-size: 18px;
  color: #ff0303;
  text-shadow: none;
}

.alert-simple.alert-primary
{
border: 1px solid rgba(6, 241, 226, 0.81);
  background-color: rgba(1, 204, 220, 0.16);
  box-shadow: 0px 0px 2px #03fff5;
  color: #03d0ff;
  text-shadow: 2px 1px #00040a;
transition:0.5s;
cursor:pointer;
}

.alert-primary:hover{
background-color: rgba(1, 204, 220, 0.33);
 transition:0.5s;
}

.alertprimary
{
    font-size: 18px;
  color: #03d0ff;
  text-shadow: none;
}

.square_box {
  position: absolute;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top-left-radius: 45px;
  opacity: 0.302;
}

.square_box.box_three {
  background-image: -moz-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  opacity: 0.059;
  left: -80px;
  top: -60px;
  width: 500px;
  height: 500px;
  border-radius: 45px;
}

.square_box.box_four {
  background-image: -moz-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  opacity: 0.059;
  left: 150px;
  top: -25px;
  width: 550px;
  height: 550px;
  border-radius: 45px;
}

.alert:before {
  content: '';
  position: absolute;
  width: 0;
  height: calc(100% - 44px);
  border-left: 1px solid;
  border-right: 2px solid;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  left: 0;
  top: 50%;
  transform: translate(0,-50%);
    height: 20px;
}

.fa-times
{
-webkit-animation: blink-1 2s infinite both;
        animation: blink-1 2s infinite both;
}
.font__weight-semibold{
  color: white !important;
  text-align: center !important;
  padding-left: 10%;
}
.detailsAd-show{
  font-weight: bold;
}
.shiftPick{
  margin-left: 10% !important ;
  width: 90% !important;
}


/**
* ----------------------------------------
* animation blink-1
* ----------------------------------------
*/
@-webkit-keyframes blink-1 {
0%,
50%,
100% {
  opacity: 1;
}
25%,
75% {
  opacity: 0;
}
}
@keyframes blink-1 {
0%,
50%,
100% {
  opacity: 1;
}
25%,
75% {
  opacity: 0;
}
}
.controlFocus{
  font-weight: bold;
}
.loader_Again {
  width: 48px;
  height: 48px;
  border: 10px solid darkorange;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin-left: 50% !important;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 






  @media(max-width: 1400px){
    .search_box{
        margin-top: 16.5%;
        margin-left: 20%;
    }
   
  }

  @media(max-width: 993px){
    .search_box{
        margin-top: 23%;
    }
  }
  @media(max-width: 640px){
    .search_box{
        display: none;
    }
  }



