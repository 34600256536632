body{
    margin: 0;
    padding: 0;

}

.container-header{
    /* background-image: url(./../images/blue7.jpg); */
    max-width: 100%;
    height: 200px;
      
    position: relative;
    
    }
    .logo1{
    
      margin-left: 0%;
      width: 150px;
      height: 100px;
      margin-top: 15px;
      
    }
    .second-div-container{
      display: flex;
      margin-left: 25%;
      /* background-color: aliceblue; */
      
    }
    .presenter22{
      width: 200px;
      height: 200px;
    
    margin-left: 10%;
    }
    .img-present{
      height: 150px;
      width: 150px;
      margin-left: 40px;
    }

  
.logo{
    width: 100px;
    position: absolute;
    height: 50px;
    border-radius: 50px;
    padding: 30px;
    margin-top: -0.5%;
    z-index: 2;
    margin-left: -13px;
}
.nav-list{
    color: aliceblue;
    display: flex;
     gap: 10px; 
    list-style-type: none;
    margin-left: 70%;
    padding: 15px;
    height: 20px;
    cursor: pointer;
    z-index: 4;
    position: relative;
    font-size: 17px;
}
#superadmin-ulnav{
  color: aliceblue;
  display: flex;
   gap: 10px; 
  list-style-type: none;
  margin-left: 60%;
  padding: 15px;
  height: 20px;
  cursor: pointer;
  z-index: 4;
  position: relative;
  font-size: 17px;
}
  

.nav-bar{
    top: 0;
    right: 0; 
     left: 0;
    display: flex;
    margin-bottom: 4%;
    background-color: black;
    position: relative;
   position: fixed;
    z-index: 4;
    height: 80px;
   
    
    
}

.nav-bar-super{
  top: 0;
  right: 0; 
   left: 0;
  display: flex;
  margin-bottom: 4%;
  background-color: #030334;
  position: relative;
 position: fixed;
  z-index: 4;
  height: 80px;
 
  
  
}

.nav-bar22{

 
    display: flex;
    margin-bottom: 4%;
    background-color: black;
    position: relative;
    position: fixed;
    z-index: 4;
    height: 80px;
    width: 100% !important;
    overflow-x: hidden;

}
.menu-icon{
  
}
.menu{
  position: absolute;
  font-size: 30px;
  display: none; 
  margin-left: -350px;
  color: white;
}
/* .nav-list{
  margin-left: 20%;
} */

/* mobile responsive */


/* laptop responsive */
/* @media(max-width:1700px){
  .nav-bar{
display: flex;
width: 1376px;
  }
.nav-list{
  margin-left: 25%;
}
.container{
  position: absolute;
  margin-left: 50px;

}
.logo{
  margin-left: -20px;
}
.show-time{
  display:none ;
  background-color: transparent;
} */




.linemobile-footer{
  display: none;
}
.radio-name{
    color: darkorange;
    margin-top: 60px;
    
}
 .pic-div{
    
    border:white solid;
    height: 200px;
    border-radius: 8px;
    
}
/* .listen-div{
  background-color: darkorange;
  width: 200px;
  height: 400px;
  
  
}  */
.onair-div{
    
    display: flex ;
    margin-left: 5%;
    gap: 20px;
    width: 200px;
    height: 200px;
     /* position: absolute;  */
    top: 5px;
    bottom: 25px;
    margin-bottom: 20px;
    
    
  
    
    
}
.lastline{
    height: 30px;
    width: 84.2%;
    margin-left: 8%;
}
.presenter{
    width: 200px;
    height: 200px;
    margin-left: 25px !important;
}
.onair-head{
    background-color: rgb(5, 5, 99);
    height: 17%;
    width: 155px;
    color: white;
    border: white solid;
    padding-bottom: 1px;
    padding-left: 15px; 
    font-size: 20px;
    border-radius: 30px;
    

}
.nav-list li:hover{
    color: darkorange;
}
.presenter-name{
    text-align: center;
    position: absolute;
    margin-top: 200px;
    /* margin-left: 20px; */
    color: white;
    font-size: 20px;
}
.onair-time{
    color: black;
    font-size: 2px;
    margin-left: -155px;
    font-size: 15px; 
    position: absolute;
    top: 70px;
    width:150px ;
    
}
.morning-show{
    color:black;
    font-size: 20px;
      margin-left: -200px; 
      position: absolute; 
     top: 35px;
     width: 200px;
     text-align: center;
      
}
.listen{
    background-color: rgb(214, 233, 250) ;  
    height: 60px;
    border-radius: 50px ;
    padding: 8px ;
    font-size: 30px ;
    color:rgb(18, 18, 173) ; 
    width: 230px ;
    padding-left: 50px ;
    cursor: pointer ;
    font-weight: bold ;
    text-decoration: none ;
     position: absolute; 
    top: 10px ;
     margin-left:15% ; 
    padding-top: 10px ;
    font-size: 30px ;
    padding-bottom: 10px ;
    }
    .playerNext{

      z-index: 4;
    }

    .play-iconed{
        /* position: absolute !important; */
        margin-top: -12px;
        margin-left: -51px !important;
        color: rgb(6, 6, 131);
        cursor: pointer;
        /* margin-left: -62px; */
        font-size: 50px;
        z-index: 25 !important;

        
        }
    .listen:hover{
        background-color:rgb(108, 180, 243) ;
    }
    .newsfeed-container{
      background-color:darkorange;
      padding: 5px;
      border-bottom: solid darkorange;
      box-shadow: 0px 1px 3px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      cursor: pointer;
      
      
    }
    .trending{
      background-color: rgba(255, 255, 255, 0.61);
      margin-right: 10px;
      height: 50px;
      
      padding: 10px;
    }
    .first-roll,
    .second-roll,
    .third-roll
    {
      padding-right: 40px;
      margin-right: 10px;
      position: relative;
    }
    .head-roll{
      font-weight: bold;
      padding-right: 40px;
      margin-right: 10px;
      position: relative;
    }
    .icon-scroll{
      padding-right: 20px;
      color: white;
      /* margin-top: px; */
      position: absolute;
      margin-left: 14px;
    }
    .newsfeed-nav{
      text-decoration: none !important;
      color: black;
    }
    .second, .first, .third{
      margin-top: 8px;
    }

/* changer */


.pchanger {
    font-style: italic;
  }
  .container {
    position: absolute;
    color: #e5e5e5;
    font-size: 1.2rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -28% !important;
    top: -37.5%;
    z-index: 2;
    
     
  }
  .footernav-sch{
     text-decoration: none;
     color: white;

  }
 
  .animation {
    height:50px;
    overflow:hidden;
    margin-left: 1rem;
    /* border-top-right-radius: 10px;
    border-top-left-radius: 10px; */
  }
  
  .animation > div > div {
    padding: 0.25rem 0.75rem;
    height:2.71rem;
    margin-bottom: 2.81rem;
    display:inline-block;
  }
  
  .animation div:first-child {
    animation: text-animation 15s infinite;
  }
  
  .first div {
    background-color:darkorange;
    padding: 20px;
    border-radius: 15px;
  }
  .second div {
    background-color: rgb(3, 3, 110);
    padding: 20px;
    border-radius: 15px;
  }
  .third div {
    background-color:black;
    padding: 20px;
    border-radius: 15px;
  }

  .listen-span{
    margin-top: -5px;
  }
  
  @keyframes text-animation {
    0% {margin-top: 0;}
    10% {margin-top: 0;}
    20% {margin-top: -5.62rem;}
    30% {margin-top: -5.62rem;}
    40% {margin-top: -11.24rem;}
    60% {margin-top: -11.24rem;}
    70% {margin-top: -5.62rem;}
    80% {margin-top: -5.62rem;}
    90% {margin-top: 0;}
    100% {margin-top: 0;}
  }
  .show-time{
    background-color:darkorange;
    position: absolute;
    right: 95.7%;
    /* padding: 8px; */
    height: 5%;
    top: 20.9%;
    color: white;
    font-weight: bold;
    padding: 15px; 
    justify-content: center;
    text-align: center;
    margin-left: -5px;
    
    
  }
/* trxt testing */
/* @use postcss-preset-env {
  stage: 0;
} */

:root {
  --color-background: darkorange;
  --stroke-width: calc(1em / 16);
  --font-size: 2.5vmin;
  --font-weight: 100;
  --letter-spacing: calc(1em / 8);
  
  
}

* {
  box-sizing: border-box;
}

/* body {
  background-color: var(--color-background);
  display: grid;
 
  margin: 0;
  min-block-size: 100vh;
  place-items: center;
  padding: 5vmin;
} */

.testing-text {
  background-clip: text;
  background-image: linear-gradient(to right, #0920f7, #3704f0, #90e0ee, #eecb07d5);
  color: var(--color-background);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  letter-spacing: var(--letter-spacing);
  padding: calc(--stroke-width / 2);
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: var(--stroke-width);
  margin-left: 20% !important;
  margin-top: 2%;
  
}


/* end */

  .take-home{
    color: white;
    text-decoration: none;
  }
  .take-home:hover{
    color: darkorange;
  }
  .listen-link{
    text-decoration: none;
  }
  .presenters-container{
    
    display: flex;
    width: 80%;
    padding:  5px;
    gap: 10px;
    margin-left: 10%;
  }
  .presenter-img{
    width: 170px;
    height: 170px;
    padding: 10px;
    justify-content: center;
    border-radius: 100px;
  }
  .presenter-img-skeleton{
    width: 100px;
    height: 150px !important;
    padding: 10px;
    justify-content: center;
    animation: skeleton-img 1s linear infinite alternate;
    border: solid;
    gap: 0;
  }

   @keyframes skeleton-img {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
.presentersid-name{
    text-align: center;
    padding: 10px;
    color: #060683;
    font-weight: bold;

  }
  .dailyprograms-head{
    color: white;
  }
 
  .presenter-all{
    background-color: rgb(22, 21, 21);
    text-align: center;
    width: 84.2%;
    margin-left: 8%;
    padding: 15px;
    justify-content: center;
  }
  .presenter-div{
    background-color: #e5e5e5;
    border-radius: 15px;
    box-shadow: 2px 2px 8px 0px ;
     width: 60%; 
    margin-left:0 !important;
  }
 .presenter-divs-ske{
    background-color: #e5e5e5;
    border-radius: 15px;
    box-shadow: 2px 2px 8px 0px ;
     width: 60%; 
    margin-left:0 !important;
  }



  .presenter-div-skeleton{
    background-color: #e5e5e5;
    border-radius: 15px;
    box-shadow: 2px 2px 8px 0px ;
      width: 50%;  
    margin-left:0% !important;
    justify-content: center;
  }


  .presenter-div:hover{
   background-color:#e5e5e5d2;;
    transition: 0.4s; 

  }
  .dropdown{
    background-color: black;
    display: none; 
    margin-top: 1px;
    padding: 30px;
    margin-right: 20px;
    transition: 5s; 
    position: absolute;
    z-index: 3;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  
  }
  
  .radio:hover .dropdown{
  display: block;
  color: white;

  
  }
  .radio-brand{
    position: absolute;
    color: darkorange;
    margin-left: 20%;
    margin-top: 15px;
    
  }
  /* @media(max-width:1700px){
    .radio-brand{
      margin-left: 10%;
    }
  } */
  .prog-nav{
    text-decoration: none !important;
    color: white;
  }
.commerce-list{
  background-color: black;
  padding: 25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
  position: absolute;
}
.commerce-drop{
  pointer-events: none;
  opacity: 0.5;
}
.commerce-drop:hover .commerce-list{
  display: block;
  color: white;
}
.drop-icon{
  position: absolute;
  padding-top: 5px;
  font-size: 30px;
  margin-left: 800px;
  margin-top: 15px;
}
.job-list{
  background-color: black;
  padding: 35px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display:none ;
  position: absolute;
  width: 200px;
  margin-left: 700px;
  margin-top: 30px;
}
.job-drop:hover .job-list{
  display: block;
  color: white;
}
.footer-container{
background-color: rgb(3, 3, 24);
padding: 20px;
max-width: 100%;
color: white;
height: 400px;
margin-top: 90px;
position: relative;
display: flex;
}
.footer-div{
  display: flex;
}
.footer-head{
  margin-left: 5%;
}
.footer-text-div{
  margin-left: 5%;

}
.footer-text{
font-size: 15px;
}
.footer-btn{
 margin-left: 15%;
 background-color: darkorange;
 padding: 10px;
 font-size: 31px;
 width: 355px;
 border-radius: 20px ;
 text-align: center;
 
}
.footer-second-div{
  margin-left: 13%;

}
.footer-email, .footer-phone, .footer-address{
font-weight: bold;
}
.one, .two, .three{
  margin-left: 20px;
  font-weight: lighter;
  color: darkorange;
}
.footer-icon-div{
  margin-left: 65%;
  background-color: rgba(255, 255, 255, 0.801);
  padding: 8px;
  width: 275px;
  display: flex;
}
@media(max-width:1700px){
  .footer-icon-div{
    padding-top: 0px;
    z-index: 2;
    position: absolute;
  }
  
}
.footer-facebook{
  
  /* margin-left: 40px; */
  color: white;
   margin-left: 5px;
   
  
}
.footer-facebook:hover{
  color: rgb(1, 93, 231) ;
  
}
.footer-youtube:hover{
  color: red ;

  
}
.footer-twitter:hover{
  color: rgb(41, 118, 235) ;
  
  
}

.footer-instagram{
  color:white;
  margin-left: 7px;
  }

  .footer-instagram:hover{
    color:brown ;
    
  }
.footer-twitter{
  color:white;
  /* padding-left: 15px; */
    margin-left: 7px;  

}
.footer-tiktok{
  color: white;
  margin-left: 7px;
 
  
}
.footer-tiktok:hover{
    color: rgb(71, 68, 68);
}
.footer-youtube{
color: white;

margin-left: 7px; 
}
.last-text{
  text-align: center;
  color: white;
}
.call-nav{
  text-decoration: none;
  color: white;
}
.getjob-nav{
  text-decoration: none;
  color:white;
}
.breaking-nav{
  text-decoration: none;
  color: white;
}
.news-nav{
  text-decoration: none;
  color: white;
}
.tv-nav{
  text-decoration: none;
  color: white;
}
.tv-nav:hover{
  color: darkorange;
}
.about-nav{
  text-decoration: none;
  color: white;
}
.about-nav:hover{
  color: darkorange;
  position: relative;
}
.advert-nav{
  text-decoration: none !important;
  color: white;
}
.main-play{
  
  z-index: 3;
}
.play-div{
  margin-bottom: 20px;
}
.first-nav-container{
  /* background-color: black; */
  height: 60px;
  position: absolute;
  margin-top: -60px;
  width: 100%;
  z-index: 3;
  display: flex;
  /* position: absolute; */
  cursor: pointer;
}
.first-flex{
  display: flex;
  color: white;
  gap: 36%;
  margin-left: 5px;
  font-size: 20px;
  font-weight: bold;
  list-style-type: none;
  cursor: pointer;
  margin-top: 15px;
  }
  /* .first-flex:hover{
    color: darkorange;
  } */
  .drop{
    position: absolute;
    margin-top: 4px;
    font-size: x-large;
  }
  .drops{
    /* position: absolute; */
    margin-top: 4px;
    font-size: large;
    
  }
  .radio-icon{
    position: absolute;
    margin-left: -30px;
    margin-top: 5px;
    z-index: 20;
  }
  
  /* .secondheader-container{

  } */

  .secondheader-div{
    color: white;
    display: flex;
    gap: 80px;
    margin-top: 4px;
    margin-left: 31%;
    font-weight: bold;
    list-style-type: none;
    cursor: pointer;
    z-index: 3;
    position: absolute;
    font-size: 15px;
    
    

  }
  .tele-icon{
    position: absolute;
    margin-left: -30px;
    margin-top: 6px;
  }
  .radio1{
    margin-left: 220px !important;
    color: white;
     /* position: absolute; */
    width: 400px; 
    margin-top: 5px;

  
    
  }

  .radio1:hover{
    color: darkorange;
  }
  .first-home{
    background-color: rgba(255, 140, 0, 0.671);
    z-index: 4;
    padding: 2px;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  .home-schedule{
    position: absolute;
    z-index: 0;
    left: 85%;
    top: 90px;
    font-size: x-large;
    font-weight: 500;
    background-color: aliceblue;
    padding: 10px;
    width: 147px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 1px 1px 4px 2px ;
    cursor: pointer;
  }
  .home-schedule:hover{
    transform: scale( 1.1, 1);
    transition: 0.5s;

  }
  .schedule-icon{
    position: absolute;
    margin-top: 6px;
    margin-left: 8px;
    color: rgb(5, 5, 99);
  }
  .presenters-name{
    font-size: 15px;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color:rgb(5, 5, 99) ;
     margin-left: -150px; 
    position: absolute;
    top: 90px;
    text-align: center;
    
  }
  .time-icon{
    position: absolute;
    margin-top: 4px;
    margin-left: 5px;
    color:rgb(5, 5, 99) ;
  }
.mic-icon{
  position: absolute;
  margin-top: 6px;
}
.radio-dropdown {
  /* position: absolute; */
  z-index: 4;
  background-color:black;
  /* height: 350px; */
  width: 450px;
  margin-left: -19%;
  padding: 30px;
  margin-top: -6px;
  display: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  
}
.tele-main{
  margin-left: 200px;
}
.radio1-li{
  margin-top: 20px;
  color: white;
}
.radio1-li:hover {
  color: darkorange !important;

}
.radio1:hover .radio-dropdown{
  display: block;
  transition: 5s;

}
.radio-dropdown:hover{
  color: darkorange;

}
.tele-main:hover{
  color: darkorange;
  cursor: pointer;
}
.tv-dropdown{
  position: absolute;
  z-index: 3;
  background-color: black;
  padding: 29px;
  width: 350px;
  margin-left: 90px;
  display: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.tvdrop{
  margin-top: 19px;
  color: white;
  text-decoration:none;
}
.tvdrop:hover{
  color: darkorange;
}
.tele-main:hover .tv-dropdown{
  display: block;
}
.job-dropdown:hover .job-listing{ 
display: block;
}
.job-dropdown:hover{
  color: darkorange;
}
.job-dropdown{
  font-size: 20px;
  /* margin-left: 5%; */
  color: white;
  list-style-type: none;
  margin-top: 20px;
  
   width: 200px; 
   z-index: 4;

}
.job-drop{
  margin-left: -200px !important;
}



.job-listing{
  position: absolute;
  color: white;
  z-index: 3;
  background-color:black ;
  padding: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: none;
  font-size: 15px;
  width: 250px;
  margin-top: -5px;
}



.jobdrop1{
  margin-top: 20px;
  color: white;
  /* opacity: 0; */
}
.jobdrop1:hover{
  color: darkorange !important;
}
.buy-dropdown:hover{
 color: darkorange;

}
.buy-listing{
  position: absolute;
  z-index: 3;
  background-color:rgb(5, 5, 99) ;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: none;
}
.buy1{
  margin-top: 20px;
  color: white;
}
.buy1:hover{
  color: darkorange;
}
.buy-dropdown:hover .buy-listing{
  display: block;
}
.news-dropdown:hover{
 color: darkorange;
}
.news-listing{
  position: absolute;
  z-index: 3;
  background-color:black;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: none;
  width: 250px;
  font-size: 15px;

}
.news-dropdown{
  font-size: 20px;
  /* margin-left: 270px; */
  color: white;
  list-style-type: none;
  margin-top: 20px;
  width: 100px;
 
}
.newsdrop1{
  color: white;
  margin-top: 20px;
}
.newsdrop1:hover{
  color: darkorange !important;
}
.news-dropdown:hover .news-listing{
  display: block;
}
.schedule-btn{
  text-decoration: none;
}
.proms-btn{
  text-decoration: none !important;
  color: darkorange;
  font-weight: bold;
  margin-top: 10px; 
  /* margin-left: 20px; */
 
}

.proms-btn :hover{
  color:white;
}
.advert-nav{
  text-decoration: none;
}
.admin{
  color: white;
}
.downbord{
  margin-top: 35px;
  width: 150px;
  position: absolute;
  margin-left: 1.5%;
}

.adcash{
  color: white;
}
.adminp{
  margin-top: 10px;
}
.footer-text-part{
  /* background-color: aqua; */
width: 100%;

 padding: 30px;
 margin-left:10% ;
}
.footer-location-div{
  /* background-color: antiquewhite; */
  width: 600px;
  padding: 20px;
  margin-left: 5%;
}
.firstfootermain-div{
display: flex;
margin-left: 10px;
}
.footer-cont{
background-color: rgb(3, 3, 24);
padding: 20px;
max-width: 100%;
color: white;
height: 400px;
margin-top: 90px;
position: relative;
top: 0;
bottom: 0;
}
.footer-schedules{
  
  display: flex;
  width: 600px;
  list-style-type:none;
  font-size: 20px;
  padding: 10px;
  margin-left: 13%;
  text-decoration: underline;
  padding: 3px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}
.secondfootermain-div{
  display: flex;
  /* background-color: aliceblue; */
}
.footer-listenlive{
  margin-left: 100px;
  width: 100px;
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  border-radius: 10px;
  background-color:#072146;
}

.footer-listenlive:hover{
  transition: 1s;
 background-color: darkorange;
}
.top-scroll{
  color: white;
  padding: 5px;
}

.footerphone{
  margin-top: 20px;
}
.footericon-main{
  margin-left: 5%;
  /* width: 100% !important; */
  /* background-color: aqua; */
  display: flex;
}
.footericon-main-mobile{
  margin-left: 5%;
  /* width: 100% !important; */
  /* background-color: aqua; */
  display: flex;
  display: none;
}


.shortcut-footer{
  margin-left: 10%;
  color: white;
  padding: 5px;
  width: 150px;
  text-align: center;
}
.shortcut-footer:hover{
  
  
  cursor: pointer;
  text-decoration: double;
  color: darkorange;
  
}
.ebenamefooter{
  color: darkorange;
  margin-left: 50px;
  
}
.footersicon{
  color: darkorange;
  font-size: 15px;
}
#jobserviceopa{
  opacity: 0.5;
  pointer-events: none;
}
.loader-play{
  position: absolute;
  margin-left: -40px;
  margin-top: -6px;
}


.mobile-div{
  display: none;
}
.nav-boot{
  margin-top: -60px !important;
  /* position: absolute !important; */
  z-index: 3;

}
.another-try{
  width: 100% !important;
  /* background-color: black !important; */
  position: relative !important;
  height: 60px !important;
  
}
#main-down{
  display: flex !important;

}
.footer-boot{
  background-color:rgba(19, 18, 18, 0.979) ;
}
.residency-footert{
  color: white;
  
  margin-left: 20px;
}
.footertext-text{
  color: white;
}
.linedown{
  color: white;
}
.footergeneral-container{
  background-color: black;
  display: block !important;
  margin-top: 10% !important;
}
.nonetwork-style{
  text-align: center;
  justify-content: center;
  margin-left: 30%;
  width: 700px;
  margin-top: 50px;
}
.hide-text {
  display: none;
}
.hide-back{
  background-color: none;
}
.navigateicon-home{
  z-index: 4;
}
#adaogidi-disable{
  opacity: 0.6;
  pointer-events: none;
}
.circlediv{
  background-color: black;
  width: 35px;
  height: 35px; 
  border-radius: 50px;
  border:1px solid #e5e5e5;
  margin-left: 10px;
  padding-top: 3px;
  background: rgb(48, 46, 46);
 
}

/* music animation */

.icon-dance {
   position: absolute; 
  display: flex;
   justify-content: space-between; 
  width: 5%;
  height: 13px;
  margin-top: 5%; 
   margin-left: 19%; 
  z-index: 3;
}
  .span-dance {
    width: 3px;
    height: 100%;
    background-color: orange;
    border-radius: 3px;
   
  }
  .unabletoloadaudiotext{
    position: absolute;
    margin-left: 20.5%;
    margin-top: 6%;
  }
  @keyframes bounce {
    10% {
      transform: scaleY(0.3); /* start by scaling to 30% */
    }
  
    30% {
      transform: scaleY(1); /* scale up to 100% */
    }
  
    60% {
      transform: scaleY(0.5); /* scale down to 50% */
    }
  
    80% {
      transform: scaleY(0.75); /* scale up to 75% */
    }
  
    100% {
      transform: scaleY(0.6); /* scale down to 60% */
    }
  }
  
  .span-dance {
    width: 3px;
    height: 100%;
    background-color: orange;
    border-radius: 3px;
    animation: bounce 2.2s ease infinite alternate;
    content: '';
  }
  .span-dance {
    width: 3px;
    height: 100%;
    background-color: orange;
    border-radius: 3px;
    transform-origin: bottom;
    animation: bounce 2.2s ease infinite alternate;
    content: '';
  }
  
    :nth-of-type(2).span-dance {
      animation-delay: -2.2s; 
    }
  
    :nth-of-type(5).span-dance {
      animation-delay: -3.7s; 
    }

    :nth-of-type(8).span-dance {
      animation-delay: -5.2s; 
    }
    
   .program-structure{
     margin-left: -50%;
   } 
   .all-modeprogram{
    display: flex;
   }
  .first-firstnav{
    display: flex;
    gap: 100px;
    /* text-align: center; */
    justify-content: center;
    margin-left: 30%;
   
    width: 130%;
  }

  

  /* player loader style */
  .lds-ellipsis {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    margin-top: -25%;
    margin-left: -70%;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: darkorange;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }


.main-item{
  display: flex;
  gap: 10%;
  margin-left: -10%;
}

.profile-image-static{
 width: 50px;
  border-radius: 50px;
  padding: 5px;
  color: white;
  margin-top: 5px;
}
.img-supername:hover{
  color: white !important;
  
}
.presenternavlink{
  text-decoration: none !important;
}
.footertext-pdiv{
  display: flex;
  color: white;
  gap: 20%;
}
.footerlist-head{
  text-decoration: underline;
}
.footertext-fade{
  color: #e5e5e5b7;
}


/* .testing-access-jag{
  margin-top: 30%;
} */


.loader-2 {
  /* display: block; */
  position: absolute;
	height: 52px;
	width: 52px;
	-webkit-animation: loader-2-1 3s linear infinite;
	        animation: loader-2-1 3s linear infinite;
          color: yellow;
          margin-left: 15% !important;
          margin-top: 1%;
}
@-webkit-keyframes loader-2-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	clip: rect(16px, 32px, 32px, 0);
	-webkit-animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-2-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 52px;
	width: 52px;
	border: 3px solid transparent;
	border-top: 3px solid darkorange;
	border-radius: 50%;
	-webkit-animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-2-3 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-3 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 52px;
	width: 52px;
	border: 3px solid darkorange;
	border-radius: 50%;
}



.superadmin-li{
  margin-top: 13px;
  margin-left: -40px;
  background-color: #0920f7;
}
.line-super{
  color: darkorange;
  height: 50px !important;
  width: 2px !important;
 
  border: rgb(175, 98, 4) solid 2px;
  border-radius: 2px;
}



/* nav modified */

.nav-modify{
  /* position: absol; */
    /* top: 0; */
    width: 100%;
     /* height: 50px;  */
    background-color: black !important;
    color:white;

}
.list-moded{
  list-style-type: none;
  background: black;
  color:white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.btn-modifiedm{
  display:none;
  position:absolute;
  right: 10px;
  top:7px;
  padding:5px;


}
.itemsss{
  margin-right:20px;
  cursor:pointer;
}
.itemt{
  
  position:absolute;
  left: 10px;
  top:7px;
  padding:5px;

}

.sectionArray{
  margin-top: 10%;
  
}

.testformarray-section{
  justify-content: center;
  text-align: center;
  margin-top: 5%;
}
.name-label{
    color: #0920f7;
    font-style: italic;
}
.name-labelbold{
  font-weight: bold;
}


.proms-logout{
  color: darkorange;
  list-style-type: none;
  margin-top: 30px;
  font-weight: bold;
  margin-left: 5%;
  cursor: pointer;
}
.proms-logout:hover{
  color: white;
}
#id-ellipsis{
  margin-left: 0% !important;
}




.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #060683 0%,
    white 29%,
    #41428A 67%,
    #FF8C00 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
   -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 20px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.containOn-air{
  background-color: #030334;
  height: 30px;
  padding-left: 5px;
  padding-right:5px ;
  margin-top: 2%;
}








.rotate-spin{
  position: absolute !important;
  margin-left: -25%;
  margin-top: -6%;
}
#name-super-ebeonadi:hover{
    color: white;
}

.display-companymessage{
  position: absolute;
  justify-content: center;
  text-align: center;
  margin-left: 40%;
}
.drop-drop-mobileup{
  /* display: none; */
}
@media(max-width: 1400px){

  .icon-dance {
  
  
   margin-top: 6%; 
    margin-left: 21%; 
  
 }
}

@media screen and (max-width: 993px){
  .list-moded {
      flex-direction: column;
      height: auto;
  }
  .list-moded:nth-child(1){
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      margin-top: 50px;
  }
  .list-moded {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      text-align: center;
      margin-right: 0px;
      padding: 20px 0;
  }
  .btn-modifiedm{
      display: block;
      background-color: black;
      
  }
  .itemt{
  
    
    left: 10px;
    top:7px;
    padding:5px;
    background-color: black;
    display: block;
    
  }
}






.Headerst {
  position: absolute;
  
  max-height: 70px;
  width: 100vw;

  display: grid;
  grid-template-areas: "logo nav";

  /* Cosmetics */
  background-color: #282c34;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* z-index: 30 !important; */
}

.Logo {
  grid-area: logo;
  height: 70px;
}

.Navst {
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
}
.Navst a {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
}
.Navst a:hover {
  transform: scale(1.1);
}
.Navst button {
  padding: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  background-color: rgba(255, 0, 0, 0.5);
  box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  transition: 70ms;
}

.Navst button:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.Burger {
  display: none;
  grid-area: burger;
  margin: 0 20px 0 0;
  padding: 0;
  justify-self: end;
  font-size: 30px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
}
.Burger:active {
  transform: scale(1.2);
}



.name-labelbold{
  width: 450px;
  height: 50px;
  border-radius: 7px;
}
.name-labelbold-txt{
  width: 450px;
  height: 100px;
  border-radius: 7px;
}

@media (max-width: 700px) {
  .Headerst {
    grid-template-areas: "logo burger" "nav nav";
  }
  .Navst {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    grid-row-gap: 20px;

    padding: 30px 0 30px;
    background: rgba(40, 44, 47, 0.95);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .Burger {
    display: inline;
  }
}

.NavAnimation-enter {
  opacity: 0;
  z-index: -99;
}
.NavAnimation-enter-active {
  opacity: 1;
  transition: opacity 400ms;
  z-index: 99;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  z-index: -99;
  transition: opacity 400ms;
}
.newnav-items{
  color: white;
  
}

.tes-secondnav{
  display: none;
}
.newnav-items:hover .tes-secondnav{
  display: block;
}
.modified-nenu-icon{
  color: white;
}
.namenav-log{
  margin-top: 12px;
  margin-left: 10px;
}


.box {
  display: none;
  width: 300px;
  }



/* testing mobile nav */
.ebetest-nav{
  color: white;
}
  .Header-fornav {
   
    top: 0; /* Stick it to the top */
    max-height: 70px;
    width: 100vw;
  
    display: grid;
    grid-template-areas: "logo nav";
  
    /* Cosmetics */
    background-color: #282c34;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .Logo-fornav{
    grid-area: logo;
    height: 70px;
  }
  .homehover{
    font-size: larger !important;
    font-weight: 400 !important;
    z-index: 5 !important;
    display: block;

   
   
  }
  .homehover2{
    font-size: larger !important;
    font-weight: 400 !important;
  }
  .homehover3{
    font-size: larger !important;
    font-weight: 400 !important;
  }
  
  .Nav-fornav {
    display: grid;
    grid-area: nav;
    grid-template-columns: repeat(4, auto);
    /* align-items: center; */
    justify-items: center;
    list-style-type: none;
    margin-left: -35%; 
    margin-top: 13px;
    
  }
  .Nav-fornav li:hover{
    color: darkorange;
  }
  .eComNave{
    text-decoration: none;
  }
  .eComNave:hover{
    text-decoration: none;
  }
  .eComNaveText{
    font-size: larger !important;
    font-weight: 400 !important;
  }

  .Nav-fornav li {
    color: #fff;
    font-size: 17px;
    font-weight: 200;
    /* transition: 0.5s; */
    text-decoration: none;
  }
  .Nav-fornav li:hover {
     transform: scale(1.1); 
  }
  .Nav-fornav button {
    padding: 10px;
    outline: none;
    border: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    transition: 70ms;
  }
  
  .Nav-fornav button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
  }
  
  .Burger {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
  }
  .Burger:active {
    transform: scale(1.2);
  }

  .letname-test{
    display: none;
    position: absolute;
    background-color: #030334;
    width: 250px;
    margin-top: 2%;
    padding: 10px;
    border-radius: 10px;
   
    
  }
  .letname-test li{
    margin-top: 5%;
    font-size: 15px !important;
  }
  .letname-test2{
    display: none;
    position: absolute;
    background-color: #030334;
    width: 150px;
    width: 250px;
    margin-top: 2%;
    padding: 10px;
    border-radius: 10px;
  }
  .letname-test2 li{
    margin-top: 5%;
    font-size: 15px !important;
  }
  .letname-test3{
    display: none;
    position: absolute;
    background-color: #030334;
   
    width: 250px;
    margin-top: 2%;
    padding: 10px;
    border-radius: 10px;
  }
  .homehov:hover .letname-test{
      display: block;
  }
 
  .letname-test3 li{
    margin-top: 5%;
    font-size: 15px !important;
  }
  .homehover3:hover .letname-test3 {
    display: block;
  }  

  .homehover:hover .letname-test {
    display: block;
    
  }
  .homehover2:hover .letname-test2 {
    display: block;
  }
  
  .icon-drop-test{
    color: white;
    font-size: 30px;
  }
  .homehover:hover .drop-drop-mobileup{
    display: block;
    position: relative;
  }
  .homehover:hover .drop-drop-mobile{
    display: none;
  }
  .radio-icon:hover .drop-drop-mobileup{
    display: block;
  }

  .homehov{
    font-size: larger !important;
    font-weight: 400 !important;
  }












  @media(max-width:1400px){

    .letname-test{
      display: none;
      position: absolute;
      background-color: #030334;
      width: 300px;
      margin-top: -1%;
      padding: 10px;
      border-radius: 10px;
      margin-left: -50px;
     
      
    }
  }
  
  @media (max-width: 700px) {
    .Header-fornav{
      grid-template-areas: "logo burger" "nav nav";
    }
    .Nav-fornav{
      grid-template-rows: repeat(4, auto);
      grid-template-columns: none;
      grid-row-gap: 20px;
  
      padding: 30px 0 30px;
      background: black;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 100vh;
      
      /* margin-left: 10% !important;  */
    }
    
    .Nav-fornav li{
      margin-left: -14%;
    }
    .Burger {
      display: inline;
    }
    .homehover{
      margin-left: -65%;
    }
    .letname-test{
      margin-left: 132px !important;
      justify-content: center;
      text-align: center;
      margin-top: -18px;
      width: 190px;
      font-size: 13px !important;
      font-weight: 100 !important;
      justify-content: center;
       height: 310px;
       border-top-left-radius: 32px !important;
    }
    
    .letname-test li{
      font-size: 15px;
      margin-top: 10%;
      margin-left: 0%;
    }
    .letname-test2{
      margin-left: 100px !important;
      justify-content: center;
      text-align: center;
      margin-top: -90px;
      width: 180px;
      font-size: 13px !important;
      font-weight: 100 !important;
      justify-content: center;
       height: 250px;;
    }
    .letname-test2 li{
      font-size: 15px;
      margin-top: 10%;
      margin-left: 0%;
    }
    .letname-test3{
      margin-left: 140px !important;
      justify-content: center;
      text-align: center;
      margin-top: -70px;
      width: 180px;
      font-size: 13px !important;
      font-weight: 100 !important;
      justify-content: center;
       height: 120px;;
    }
    .letname-test3 li{
      font-size: 15px;
      margin-top: 10%;
      
      margin-left: 0%;
    }
    .homehover{
      font-size: larger !important;
      font-weight: 100 !important;
    }
    .homehover2{
      font-size: larger !important;
      font-weight: 100 !important;
      margin-top: -20%;
      margin-left: -31% !important;
    }
    .homehover3{
      font-size: larger !important;
      font-weight: 100 !important;
      margin-top: -40% !important; 
      margin-left: -18% !important;
    }
    .eComNave{
       margin-top: -30%;
      margin-left: -18% !important;
      margin-top: -30% !important;
    }


  }
  
  .NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
  }
  .NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
  }
  .NavAnimation-exit {
    opacity: 1;
  }
  .NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
  }
  
/* end */
.ada-ogidi-disable{
  opacity: 0.2;
}


  


@media (max-width: 576px) {
  .nav-item {
      width: 100%;
      text-align: left;
  }

  .v-line {
      display: none;
  }
}

@media(max-width:1400px){
  .first-firstnav{
    display: flex;
    gap: 100px;
    /* text-align: center; */
    justify-content: center;
    margin-left: 20%;
   
    width: 130%;
  }
 } 
  
 /* ipad responsive */
@media(max-width:993px){
   .logo1{
    margin-left: -100%;
   }
  .onair-div{
     margin-left: -40%;
     margin-top: 1%; 
  }
  .home-schedule{
    display: none;
  }
  .listen{
     margin-top: 9%;
     margin-left: 30%; 
  }
  .container{
    display: none;
  }
  .show-time{
    height: 10px;
    width: 55px;
  }
  .presenters-container{
    display: grid;
    grid-template-columns: auto auto;
  }
  .footer-listenlive{
    display: none;
    margin-left: -20px;
  }
  .home-container{
    overflow-x: hidden;
  }
  .togglescroll{
    background-color: white !important;
  }
  #main-down{
    background-color: black !important;
  }
  .job-dropdown{
   
    margin-left: 5px;
  }
  .news-dropdown{
    margin-left: 5px;
  }
  #radio-iconhide{
    display: none;
  }
  .footertext-pdiv{
    display: grid;
    color: white;
    gap: 5%;
    text-align: start;
  }

  .presenter-div{
    background-color: #e5e5e5;
    border-radius: 15px;
    box-shadow: 2px 2px 8px 0px ;
     width: 70%; 
    margin-left:0 !important;
  }
  .loader-2 {
    /* display: block; */
    position: absolute;
    height: 52px;
    width: 52px;
    -webkit-animation: loader-2-1 3s linear infinite;
            animation: loader-2-1 3s linear infinite;
            color: yellow;
            margin-left: 30% !important;
            margin-top:10%;
            z-index: 10;
  }
  .testing-text{
    font-size: 15px;
    margin-top: 18px;
    display: none;
  }
  .radio-icon{
    display: none;
  }
  .homehov{
    font-size: 20px !important;
    /* font-weight: 00 !important; */
  }
  .testing-text{
    display: none;
  }
  .ebeTap{
    display: none;
  }

  
}


/* mobile responsive */
@media(max-width:680px){
  
  .footer-instagram{
    color:white;
    margin-left: 7px;
    margin-top: 30px !important;
    }
  
    .secondheader-div{
      display: none;
    }
    .presenter-div{
      width: 250px;
      margin-left: -13.5% !important;
      margin-top: 2%;
    }
    .presenter-all{
      width: 270px;
      margin-left: 13%;
      top: 0%;
      
    }
    .lastline{
      width: 270px;
      margin-left: 13%;
    }
    .listen{
      margin-top: 60px;
      margin-left: -70%;
      /* position: absolute; */

    }
    .footer-div{
      display: grid;
    }
    .footer-icon-div{
      margin-left: 20px;
    
    }
    .footer-second-div{
      background-color: black;
      margin-left: -20px;
      padding: 10px;
      margin-top: -20px;
      width: 112% ; 
      padding-left: 30px;
      z-index: 2;
    }
    .footer-btn{
      margin-left: -25px;
      padding: 10px;
      font-size: 25px;
      padding-left: 20px;
      text-align: center;
      }
    .footer-text{
      text-align: center;
      
    }
    .footer-head{
      font-size: 25px;
    }
    .sin-bank{
      display: block;
      top: 200px;
      margin-left: -32px;
      position: absolute;
      z-index: 3;
      width: 60px;
      font-size: 15px;
      height: 8px;
      border-radius: 0;
      border-bottom-left-radius:5px ;
      
      
    }
    .schedule-icon{
      display: none;
    }
     .newsfeed-container{
      
    } 
    .tele-main{
      font-size: 18px;
      margin-left: -10px;
    }
    .secondheader-container{
      background-color:rgb(5, 5, 99) ;
    
      height: 40px;
      width: 100%;
      
    }
     .secondheader-div{
    
      background-color: black;
      /* margin-top: 70px; */
      /* margin-left: -45px;  */
      width: 300px;
      height: 300px;
      display: none;
    }
    .footer-icon-div{
      margin-left: 6%;
      display: flex;
      margin-top: 5px;
      
    } 
    .secondheader-div-sec{
      color: white;
      display: grid;
      gap: 0px;
      margin-top: 4px;
      margin-left: 31%;
      /* font-weight: bold; */
      list-style-type: none;
      cursor: pointer;
      z-index: 3;
      /* position: absolute; */
      font-size: 15px;
      

    }
    .lastline{
      margin-top: 60px;
    }
    .footer-location-div{
      /* background-color: antiquewhite; */
      display: flex;
      width: 30%;
      margin-left: -20px;
      display: none;
    }
    .footer-cont{
      overflow: hidden;
      
     
      display: grid;
      background-color: black !important;
      width: 100%;
    }
     .footer-listenlive{
      margin-left: 0;
      position: relative;
      display: none !important;
     }
    .firstfootermain-div{
      /* display: grid; */
      /* background-color: aquamarine; */
      
    }
    .footer-text-part{
      background-color: black;
      max-width: 100% !important;
      margin-left: -40px;
      margin-top: 98px;
  
      
    }
    .footericon-main{
      background-color: black;
      margin-left: -20px;
      /* width: 100%;  */
      
    }

    .footericon-main-mobile{
      background-color: black;
      margin-left: 18%;
     display: block;
     display: flex;
      
    }
    


    .ebenamefooter{
      text-align: center;
    }
    .footer-schedules{
      display: none;
    }
   .secondfootermain-div{
    display: none;
   }
   .first-flex{
    
    display: block;
   }
   .mobile-div{
    /* background-color: aqua; */
    display: block;
    position: absolute;
    width: 200px;
    margin-left: -30px;
    
   } 
   .first-first{
    margin-top: -30px;
    margin-left: 40px;
   }
   .radio-icon{
    display: none;
    list-style-type: none;
   }
   .radio-dropdown{
    margin-left: -20px;
    list-style-type: none;
    width: 380px;
    background-color: black;
    z-index: 3;
    /* height: 500px !important; */
    
   }
   .menu{
    display: block !important;
    color: white;
    position: absolute;
    margin-top: 20px !important ;
  
  }
  .menu:hover .secondheader-div-sec{
   display: block !important;
  }
  .downbord{
    margin-left: 30px;
  }
  .secnum-nav{
    height: 100px !important;
}
#main-down{
  display: grid;
  /* margin-top: 40px ; */
  background-color: black;
  color: white;
  width: 100% !important;
  height: 100% !important;
  font-size: 15px !important;
  border-radius: 10px;

  
 
}



  
}
@media(max-width:680px){

  #superadmin-ulnav{
    display: none;
  }

  .rotate-spin{
   
    margin-left: -38%;
    margin-top: -9%;
  }
  .menu-icon{
  
 
  position: absolute;
  z-index: 15;
  
    }
    .nav-bar{
     
      background-color: black;
     
    }
    .nav-list{
      display:none ;
      position: absolute;
      
      
    }
    .logo{
      position: absolute;
      margin-left: -15px;
      top: -12px;
    }
    .radio-brand{
      position: absolute;
      left: 21%;
      top: -2px;
    }
    .mobile-nav{
      position: relative;
    }
    .onair-div{
      position: absolute;
      /* max-width: -20px !important;  */
      margin-left: 5%  !important; 
      margin-top: 2%;
    }
    .presenter{
      max-width: 150px;
      max-height: 150px;
    }
    .pic-div{
      max-width: 150px;
      max-height: 150px;
      margin-top: 8%;
    }
    .onair-head{
      height: 15%;
      max-width: 170px;
      margin-left: 25px;
    }
    .presenter-name{
      top: -5px;
      padding-left: 15px;
    }
    .container{
      display: none;
    }
    .animation{
      display: none;
    }
    .show-time{
     
      width: 60px;
      top:25%;
    }
    .pchanger{
      display: none;
    }
     /* .morning-show{
      margin-left: 39px;
    }  */
    .show-time{
      margin-top: 35px;
      margin-top: 0%;
      background-color:darkorange;
      height: 40px !important;
      display: none;
    }
    /* .onair-time{
      margin-left: 27px;
    } */
    .listen{
       position: absolute; 
      size: 20px;
      max-width: 150px;
      /* position: fixed; */
      z-index: 5;
      margin-left: 90%;
      margin-top: 28%;
      }

  .home-schedule{
    display: none;
  }
  .live-no{
    display: none;
  }
  .first-home{
    opacity: 0;
  }
  .radio1{
     margin-left: 12px !important; 
    /* position: absolute; */
   
    list-style-type: none;
    font-size: 15px !important;
  }
  .tele-icon{
    display: none;
  }
  
  .tv-dropdown{
    left: 70px;
  }
  
  .logo1{
    display: none;
  }
  .presenter22{
    
  }
   .container-header{
  width: 100%;
  } 
  .job-dropdown{
    font-size: 15px !important;
    margin-left: -80% !important;
    margin-top: -20%;
   
    
  } 
  .news-dropdown{
    font-size: 15px;
    margin-left: -80% !important;
    list-style-type: none;
    margin-top: -20%;
  }
  #conntrol-radio-nav{
    margin-left: -55% !important;
    margin-top: 20%;
    z-index: 6;
  }
  .job-listing{
    margin-left: 0px;
    padding: 10px;
  }
  .news-listing{
    margin-left: -30px;
  }
  .footer-boot{
    background-color: black;
    width: 100%;
    text-align: center !important;
  }
  .last-text{
    color: white;
  }
  .footertext-text{
    color: white;
    margin-left: 15%;
  }
  .togglescroll{
    color: white !important;
    background-color: white !important;
  }
  .nonetwork-style{
    margin-left: 0;
    
  }
  .listen-span{
    position: absolute;
    
  }
  .testing-text{
    font-size: 15px;
    margin-top: 18px;
    display: block;
  }
  .presenters-container{
    display: grid;
    grid-template-columns: auto;
    }
    .icon-dance {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 20%;
      height: 13px;
      margin-top: 35%;
      margin-left: 120%;
      z-index: 5;
    }
    .img-present{
      margin-left: 50%;
     
    } 
    .first-firstnav{
      display: grid;
      height: 300px !important ;
      
      margin-left: 5% !important;
      z-index: 4 !important;
      
    } 
    .unabletoloadaudiotext{
      position: absolute;
      margin-left: 30% !important;
      margin-top: 53%;
     
    }

    .linemobile-footer{
      display: block;
      width: 100% !important;
      margin-left: 13%;
    }
    .main-item{
      display: grid;
    }
    #navbarScroll{
      z-index: 4 !important;
    }
    .footertext-pdiv{
      display: grid;
      color: white;
      gap: 10%;
      text-align: start;
    }

  .presenter-div-skeleton{
      background-color: #e5e5e5;
      border-radius: 15px;
      box-shadow: 2px 2px 8px 0px ;
        width: 50%;  
      margin-left:14% !important;
      justify-content: center;
      gap: 0;
    }
    .linedown{
      color: white;
      margin-top: 20%;
    }
    .profile-image-static{
      display: none;
    }
  .loader-2 {
      /* display: block; */
      position: absolute;
      height: 52px;
      width: 52px;
      -webkit-animation: loader-2-1 3s linear infinite;
              animation: loader-2-1 3s linear infinite;
              color: yellow;
              margin-left: 89% !important;
              margin-top:35%;
              z-index: 10;
    }
    .proms-logout{
      display: none;
    }

  .box {
      display: block;
      width: 300px;
      }
      .homehov{
        font-size: 20px !important;
       margin-left: -31% !important;
      }
      
      .profile-img {
        background: #e8e8e8;
        width: 4rem;
        height: 4rem;
        border-radius: 10%;
        overflow: hidden;
        margin-left: 30%;
        
        
      }
      
      .skeleton-placeholder {
        background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #e8e8e8 80px);
        background-size: 350px;
        width: 78%;
        height: 1.45rem;
        border-radius: 3px;
        margin-top: 1.5rem;
        animation: animation 2.5s infinite;
      }
    }
    
    @keyframes animation {
      0% {
        background-position: -100px;
      }
      40%,
      100% {
        background-position: 270px;
      }

      
    }
    
    
   
  
  
  


